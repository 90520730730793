import { app as fbApp } from "./../../firebase";
import { getAnalytics } from "firebase/analytics";

export default {
  install(app) {
    const analytics = getAnalytics(fbApp);
    app.config.globalProperties.$analytics = analytics;

    // provide for usage inside setup
    app.provide("analytics", analytics);
  },
};
