import posthog, { PostHog } from "posthog-js";
import { useAuthUserStore } from "@/stores/auth";

class PosthogWrapper {
  eventBus: any;
  disabledSessionRecording: boolean;
  apiKey = import.meta.env.VITE_APP_POSTHOG_API_KEY;
  posthogInstance: PostHog;
  flags: any;

  constructor() {
    this.disabledSessionRecording =
      !(
        import.meta.env.PROD === true ||
        import.meta.env.VITE_APP_PROD === "true"
      ) ||
      import.meta.env.VITE_APP_POSTHOG_DISABLE_SESSION_RECORDING === "true" ||
      this.apiKey === undefined ||
      window?.location?.hostname === "localhost";

    this.posthogInstance = posthog.init(this.apiKey, {
      api_host: import.meta.env.VITE_APP_POSTHOG_INSTANCE_ADDRESS,
      disable_session_recording: this.disabledSessionRecording,
      autocapture: false,
      enable_recording_console_log: false,
    }) as PostHog;

    posthog.onFeatureFlags((flags) => {
      this.flags = flags;
      useAuthUserStore().setFeatureFlags(flags);
    });
  }
}

export default {
  install(app) {
    const wrapper = new PosthogWrapper();
    app.config.globalProperties.$posthog = wrapper.posthogInstance;
    // provide for usage inside setup
    app.provide("posthog", wrapper.posthogInstance);
  },
  setUser(user: any) {
    posthog.identify(user.uid, { email: user.email, name: user.displayName });
  },
};
