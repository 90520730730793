import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  reauthenticateWithPopup,
  signInWithCustomToken,
  updateProfile,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  signInWithCredential,
  type User as FirebaseUser,
} from "firebase/auth";

import { getAnalytics, setUserId } from "firebase/analytics";

import { auth } from "@/firebase";
import { defineStore } from "pinia";
import { CookieComponent } from "@/assets/ts/components";
import { useRouter, useRoute } from "vue-router";
import router from "@/router/routes";
import jwtService from "@/core/services/JwtService";
import { AccountUser } from "@/assets/ts/types";

export type ChanlUser = FirebaseUser & {
  apiToken: string;
  userId: string;
};

export type SignupCredentials = {
  email: string;
  password: string;
  displayName: string;
};

// export interface User {
//   name: string;
//   surname: string;
//   email: string;
//   password: string;
//   api_token: string;
// }
//
// export interface UserAuthInfo {
//   errors: unknown;
//   user: VidlogsUser;
//   isAuthenticated: boolean;
// }

interface State {
  errors: any;
  user: ChanlUser;
  accountUser: any;
  isAuthenticated: boolean | null;
  counter: number;
  hash: string; // Intercom uid hash
  featureFlags: [];
}

export const useAuthUserStore = defineStore("auth", {
  /**
   * State
   */
  state: (): State => ({
    errors: {},
    user: {} as ChanlUser,
    hash: "",
    accountUser: {},

    // Posthog feature flags
    featureFlags: [],

    // isAuthenticated is always false on load and is
    // resolved by the onStateChange hook

    // Dec 9th - Navi
    // but the problem with onStateChange is it's async
    // & the whole UI needs to wait for it to be resolve first
    // before we can determine that it's actually true or false
    // which Vue doesn't like
    isAuthenticated: Boolean(JwtService.getToken()),
    counter: 1,
  }),

  /**
   * Getters
   */
  getters: {
    simplerOnboarding(state) {
      return state.featureFlags["simpler-onboarding"];
    },

    currentUser: (state) => {
      return state.user;
    },

    currentUserId: (state) => {
      return state.user.uid;
    },

    /**
     * Verify user authentication
     * @returns boolean
     */
    isUserAuthenticated: (state) => {
      return state.isAuthenticated;
    },

    /**
     * Get authentication errors
     * @returns array
     */
    getErrors: (state) => {
      return state.errors;
    },

    currentRoute() {
      return router.currentRoute.value;
    },
  },

  /**
   * Actions
   */
  actions: {
    setFeatureFlags(flags) {
      this.featureFlags = flags;
    },

    /**
     * checks if there's an invitation, a redirect_url & redirects to it
     *  or redirects to the channel page if none
     */
    completeRedirection() {
      const inviteStr = CookieComponent.get("post-invite");
      const route = this.currentRoute;

      // not redirecting if the user is refreshing the page within these pages
      const allowedPages = [
        "channels",
        "shared",
        "media",
        "invite",
        "profile",
        "embedded",
      ];
      if (allowedPages.some((page) => route.fullPath.includes(page))) {
        console.debug(
          "[@completeRedirection] user is already within main pages.."
        );

        // redirect for invites
        if (route.query["redirect"] === "post-invite" && inviteStr) {
          router.push({
            name: "show-invite",
            params: {
              invite: inviteStr,
            },
          });
        } else if (route.query["redirect"]) {
          router.replace(route.query["redirect"] as string);
        }

        return;
      }

      // redirect for chrome extension
      else if (
        route.query["response_type"] === "bearer" &&
        route.query["redirect_uri"]
      ) {
        // Token is already exchanged at this point
        let url = route.query["redirect_uri"];
        url += "?bearer=" + JwtService.getToken();
        window.location.href = url as string;
      }
      // redirect to dashboard
      else {
        if (inviteStr) {
          CookieComponent.delete("post-invite");
        }
        router.push({ name: "channels" });
      }
    },

    /**
     *
     * @param credentials {email, password}
     */
    async login(credentials) {
      let user: FirebaseUser;
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        );
        user = userCredential.user;
        this.setAuth(user);
        const idToken = await user.getIdToken();
        await this.exchangeToken(idToken);
        console.log("[login] Set the user token ", userCredential, user);
      } catch (reason) {
        this.setError(reason);
        // this.context.commit(Mutations.SET_ERROR, reason);
      }
    },

    async googleAuth() {
      const provider = new GoogleAuthProvider();

      try {
        const result = await signInWithPopup(auth, provider);

        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;
          const user = result.user;

          this.setAuth(user);

          const idToken = await user.getIdToken();
          await this.exchangeToken(idToken, credential);

          console.debug(" Credentials and user from google ", credential, user);
        }
      } catch (error: any) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData?.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        throw error;
      }
    },

    async logout() {
      await this.purgeAuth();
      await this.purgeCache();
    },

    purgeCache() {},

    /**
     * Verify the auth credentials
     * this method will be called on each page load
     * so this should call verifyToken only once in a while
     * possibly via
     */
    async verifyAuth() {
      // main.ts getAuth function will remove the token when user logs out
      return !!jwtService.getToken();
    },

    /**
     * @param credentials
     */
    async register(credentials: SignupCredentials) {
      try {
        const { data } = await ApiService.post("/auth/signup", credentials);
        const { apiToken } = data;

        const userCredential = await signInWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        );

        const user = userCredential.user;

        // Token is already exhanged from the server
        this.setAuth(user);
        this.setToken(apiToken);

        // .. only verify it?
        await this.verifyToken();
      } catch (err) {
        let error = null;
        if (err.response && err.response.data) {
          // API Errors
          error = err.response.data.errors;
        } else {
          error = err.message;
        }
        this.setError(error);
      }
    },

    /**
     * Let firebase signup happen and then
     * exchange the token with the backend
     * @param token
     */
    async googleOneTap(token: string) {
      const credential = GoogleAuthProvider.credential(token);
      const userCredential = await signInWithCredential(auth, credential);
      const idToken = await userCredential.user.getIdToken();
      const user = userCredential.user;

      await this.exchangeToken(idToken);
      this.setAuth(user);

      // onAuthStateChanged maybe triggered before the token is set in the client from API
      this.completeRedirection();
    },

    /**
     *
     * @param payload
     * @returns
     */
    async updateProfileDetails(payload: Object) {
      try {
        await updateProfile(auth.currentUser, payload);
        return true;
      } catch (error) {
        console.clear();
        console.log("Update profile information", error);
      }
    },

    /**
     *
     * @param email
     * @returns
     */
    async updateEmailAddress(email: string) {
      const { providerData } = this.user;

      try {
        for (const prov of providerData) {
          if (prov.providerId === "google.com") {
            const provider = new GoogleAuthProvider();
            await reauthenticateWithPopup(auth.currentUser, provider);
            await updateEmail(auth.currentUser, email);
          } else if (prov.providerId === "password") {
            await updateEmail(auth.currentUser, email);
          }
        }
        return true;
      } catch (error) {
        console.clear();
        console.log("Update email address", error);
      }
    },

    /**
     *
     * @param password
     * @returns
     */
    async resetPassword(password: string) {
      const { providerData } = this.user;

      try {
        for (const prov of providerData) {
          if (prov.providerId === "google.com") {
            const provider = new GoogleAuthProvider();
            await reauthenticateWithPopup(auth.currentUser, provider);
            await updatePassword(auth.currentUser, password);
          } else if (prov.providerId === "password") {
            await updatePassword(auth.currentUser, password);
          }
        }
        return true;
      } catch (error) {
        console.clear();
        console.log("Reset password", error);
      }
    },

    /**
     * @param payload
     */
    async forgotPassword(payload) {
      try {
        const { email } = payload;
        this.errors = {};
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        this.setError(error);
      }
    },

    /**
     *
     * @param code
     */
    async saveCode(code: string) {
      const { data } = await ApiService.post("/auth/code", {
        code: code,
      });

      // firebase auth login exchanged code tokens
      // await signInWithCustomToken(auth, data);
    },

    /**
     * exchangeToken exchanges & sets the firebase token with a standard API token
     */
    async exchangeToken(idToken: string, credentials?): Promise<void> {
      // Get user's JWT for rest of the API flow
      const { data } = await ApiService.post("/auth/token", {
        idToken: idToken,
        credentials: credentials,
      });
      this.setToken(data.apiToken);
      this.setUidHash(data.hash);

      // verify & set the auth
      await this.verifyToken();
    },


    async getAccountUser(accountId: string, userId: string): Promise<AccountUser> {
      // get users data
      const { data } = await ApiService.get(`/accounts/${accountId}/users/${userId}`);
      return data as AccountUser
    },

    /**
     * verifyToken verifies the standard API token
     */
    async verifyToken(): Promise<void> {
      try {
        const idToken = JwtService.getToken();

        if (!idToken) {
          return null;
        }

        ApiService.setHeader();

        const payload = {
          idToken: idToken,
        };

        // verify also creates a hash for intercom
        const { data } = await ApiService.post("/auth/verify", payload);

        this.setUidHash(data.hash);
        this.setAuth(data);
      } catch (error) {
        console.error("unable to verify token:", error);
        await this.logout();
      }
    },

    setError(error = {}) {
      this.errors = { ...error };
    },

    setToken(token) {
      this.user.apiToken = token;
      JwtService.saveToken(token);
    },

    /**
     *
     * @param user Sets the current accounts user
     *   is different from firebase auth user
     */
    setAccountUser(user) {
      this.accountUser = user;
    },

    /**
     * Set's
     * @param user
     */
    setAuth(user) {
      this.isAuthenticated = true;
      this.user = user;
      this.errors = {};

      const analytics = getAnalytics();
      setUserId(analytics, user.uid || user.id);

      // set locally for persistence
      localStorage.setItem("currentUser", JSON.stringify(user));
    },

    setPassword(password) {
      // this.user.password = password;
    },

    async purgeAuth() {
      await this.$reset();
      await auth.signOut();
      JwtService.destroyToken();

      // remove locally persistent current account and user
      localStorage.removeItem("currentAccount");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentChannel");

      console.warn("logged out from chanl.ai..");
    },

    /**
     * Set up the uid hash for intercom
     * @param hash
     */
    setUidHash(hash) {
      this.hash = hash;
    },
  },
});
