import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { get } from "lodash";

import { onError } from "@apollo/client/link/error";
import { logErrorMessages } from "@vue/apollo-util";
import JwtService from "@/core/services/JwtService";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { CachePersistor, LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { useChannelStore } from "./stores/channel";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

const PERSIST_LAST_PURGE_KEY = "PERSIST_LAST_PURGE_KEY";
const PERSIST_TTL = 20 * 60 * 60 * 1000; // 24 hours

class GraphqlWithCacheManager {
  public apolloClient: ApolloClient<any>;
  public apolloProvider: any;

  /**
   * Send authentication errors to main
   */
  public onAuthError = (error: any) => {};
  private persistCache: any;
  /**
   * Persistent cache
   * https://github.com/apollographql/apollo-cache-persist
   */
  private cache;
  persistor;

  // Create an http link
  private readonly wsUrl = import.meta.env.VITE_APP_API_URL?.replace(/(http)(s)?\:\/\//, "ws$2://");

  /**
   * socket link for subscriptions
   * @private
   */
  private wsLink: any;
  /**
   * socket link for queries
   * @private
   */
  private httpLink: any;
  /**
   * merged link for both above
   * @private
   */
  private mergedLink: any;
  /**
   * track errors
   * @private
   */
  private errorLink: any;

  constructor(config: any = {}) {}

  /**
   * Initialize manager
   */
  async init() {
    this.defineLinks();
    this.cache = new InMemoryCache({
      // https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
      // https://github.com/apollographql/apollo-feature-requests/issues/6
      addTypename: false,
    });
    // this.persistor = this.createCachePersistor();

    this.createApolloClientWithCache();

    /**
     * We dont keep cache forever
     */
    // this.persistor.purge();
    // const purged = await this.cachePurgeOrRestore(PERSIST_TTL);

    // this.persistCache = await persistCache({
    //   cache: this.cache,
    //   storage: new LocalStorageWrapper(window.localStorage),
    //   debug: true,
    //   trigger: "write",
    // });

    // let it be chained
    return this;
  }

  /**
   * Define links
   */
  defineLinks() {
    // Create a WebSocket link:
    this.wsLink = new WebSocketLink({
      uri: this.wsUrl + "/graphql",
      options: {
        reconnect: true,
      },
    });

    this.httpLink = new HttpLink({
      uri: import.meta.env.VITE_APP_API_URL + "/graphql",
      fetch: (uri: RequestInfo, options: RequestInit) => {
        options.headers = this.getHeaders();
        return fetch(uri, options);
      },
    });

    this.mergedLink = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      this.wsLink,
      this.httpLink
    );

    this.errorLink = onError((error) => {
      console.log("Error link", error);
      if ("Unauthorized" === get(error, "graphQLErrors[0].message")) {
        this.onAuthError(error);
      } else if (process.env.NODE_ENV !== "production") {
        // no need to output Unauthorized messages
        logErrorMessages(error);
      }
    });
  }

  createCachePersistor() {
    return new CachePersistor({
      cache: this.cache,
      storage: window.localStorage as any,
      maxSize: 4.5 * 1024 * 1024,
    });
  }

  async cachePurgeOrRestore(ttl: number) {
    const lastPurge = localStorage.getItem(PERSIST_LAST_PURGE_KEY);
    let purged = false;
    if (!lastPurge || Number(lastPurge) < Date.now() - ttl) {
      localStorage.setItem(PERSIST_LAST_PURGE_KEY, String(Date.now()));
      this.persistor.purge().catch((err) => console.log(`Cache purge error: ${err}`));

      purged = true;
    } else {
      this.persistor.restore().catch((err) => console.log(`Cache restore error: ${err}`));
    }

    return purged;
  }

  /**
   * Persistor
   */
  purgeCacheIfUserChanged(user) {
    if (!this.persistor) {
      return;
    }

    //User must be logging out, should we keep the data?
    if (!user) {
      // return;
    }

    const lastKnownUser = localStorage.getItem("lastKnownUserId");
    if (lastKnownUser !== user?.uid) {
      // console.log("Cache purge user: " + user?.uid, lastKnownUser);
      this.persistor.purge();
    }

    localStorage.setItem("lastKnownUserId", user?.uid);
  }

  getHeaders() {
    const channelStore = useChannelStore();
    const headers: { Authorization?: string; "Content-Type"?: string } = {};
    const token = JwtService.getToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    //Try to get route parameters for accountId
    // Fri Jan 27, 2023
    // Uncaught (in promise) ApolloError: Cannot read properties of undefined (reading 'params')
    const route = useRoute();
    const { currentAccount, currentAccountId } = storeToRefs(channelStore);

    // Added `split` because of shared media pages & shared notes pages.
    // Shared pages has both the mediaId and accountId in its' route slug
    // so depending only `params?.channelId` without picking out `media`
    // gives both `accountId` and `mediaId`

    /**
     * This is a temporary hack
     *
     * This class appears to be called several times and the last time
     * it's called we don't have "currentAccountId" available, so we store
     * the value once it's available.
     */
    if (route?.params) {
      sessionStorage.setItem("currentAccountId", route?.params?.channelId?.toString()?.split("-")[0]);
    }

    headers["accountId"] =
      route?.params?.channelId?.toString()?.split("-")[0] ||
      currentAccount.value?.id ||
      currentAccountId.value ||
      sessionStorage.getItem("currentAccountId");

    headers["Content-Type"] = "application/json";

    return headers;
  }

  createApolloClientWithCache() {
    // Create the apollo client
    this.apolloClient = new ApolloClient({
      cache: this.cache,
      link: this.errorLink.concat(this.mergedLink),
      connectToDevTools: true,
    });

    this.apolloProvider = createApolloProvider({
      defaultClient: this.apolloClient,
    });
  }
}

const gqlManager = new GraphqlWithCacheManager({});
//
// Top level await requires target="es2017" in tsconfig
// https://typescript.tv/new-features/top-level-await-in-typescript-3-8/
//
await gqlManager.init();
export default gqlManager;
