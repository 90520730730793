import emitter from "tiny-emitter/instance";

export const ModalIds = {
  uploadVideoModal: "mediaUploadModalId",
  transcriptModal: "transcriptModalId",
  templatesModal: "templatesModalId",
  browseNotesModal: "browseNotesModal",
  encodeModal: "encodeModalId",
  channelLogoBrowserModal: "channelLogoModalId",
  channelBgBrowserModal: "channelBgModalId",
  createEditMeetingModal: "createEditMeetingModalId",
  shareMediaModal: "shareMediaModalId",
  shareMediaBaseModal: "shareMediaBaseModalId",
  shareHubspotModal: "shareHubspotModalId",
  teamInviteModal: "teamInviteModalId",
  downloadTranscriptModal: "downloadTranscriptModalId",
  createCollectionModal: "createCollectionModalId",
  editResourceMetadataModal: "editResourceMetadataModalId",
  upgradeAccountPlanModal: "upgradeAccountPlanModalId",
  createClipModal: "createClipModal",
};

export enum EventTypes {
  selectHeaderImage,
  showModal,
  hideModal,
  selectLogoImage,
  seekPlayer,
  playerTimeUpdate,
  playerPlaying,
  playerPaused,
  playerEnded,
  playerSeeked,
  setPlayerPlay,
  forwardPlayer,
  rewindPlayer,
  currentTakeawayUpdate,
  destroyPlayer,
  editComment,
  GlobalTranscriptEditing,
  updatingTranscript,
  setTakeAwayAside,
  changeMediaAccessLevel,
  searchContact,
  showTranslation,
  getTranslation,
  processingTranslation,
  translate,
  showHighlightDetail,
  showNoteDetail,
  updatingHighlight,
  addToShareWith,
  highlightDeleted,
  searchChanged,
  collectionChanged,
  updateCollection,
  setCurrentCollection,
  resetCollection,
  resetResources,
  attachNoteToMedia,
  //👇 this is a hack for subscriptions on notes, this will be removed
  // when we have a full-user story on notes that involves subscriptions
  NoteListChanged,
  commentCreated,
  setActiveTakeAway,
  mediaDetails,
  userChanged,
  CalendarEventScheduled,
  showSpeakerMap,
  refetchMediaFeed,
  transcriptSearchParamChanged,
  browseTranscriptSearchResults,
  receivedSearchResults,
  clearTranscriptSearchParams,
  refetchHighlightsFeed,
  refetchMediaStatuses,
  searchingTranscript,
  refetchTranscriptSegments,
}

export interface IEventEmitter {
  $on: (name: EventTypes, args: any) => void;
  $off: (name: EventTypes, args: any) => void;
  $once: (name: EventTypes | string, args: any) => void;
  $emit: (name: EventTypes | string, args: any) => void;
}

export const EventBus: IEventEmitter = {
  $on: (name, ...args) => emitter.on(name, ...args),
  $once: (name, ...args) => emitter.once(name, ...args),
  $off: (name, ...args) => emitter.off(name, ...args),
  $emit: (name, ...args) => emitter.emit(name, ...args),
};

export const plugin = {
  install: (app) => {
    // inject a globally available $utils object that can be used for static methods
    // $utils.initials(user)
    app.config.globalProperties.$bus = EventBus;
  },
};
