import { User } from "@firebase/auth";
import { EventBus } from "@/assets/ts/EventBus";
import { useRouter } from "vue-router";

/**
 * !Any form of class instantiation of intercom like private intercom = (window as any).Intercom breaks its functionality
 * seems to be a race condition that needs to be investigated further
 */
const appId = "ize0wdp0";
let intercom = (window as any).Intercom;

export class IntercomWrapper {
  private isBooted: Boolean = false;
  router = useRouter();

  boot(user: any) {
    // this.intercom("shutdown");

    if (this.isBooted) {
      return;
    }

    const bootParams = this.prepareUserData(user);
    (window as any).Intercom("boot", bootParams);
    setTimeout(() => {
      (window as any).Intercom("show");
    }, 1000);

    this.isBooted = true;
    EventBus.$emit("intercom:booted", {});
  }

  update(user) {
    const updateParams = this.prepareUserData(user);
    (window as any).Intercom("update", updateParams);
  }

  show() {
    if (!this.isBooted) {
      EventBus.$once("intercom:booted", () => {
        this.show();
      });
      return;
    }
    (window as any).Intercom("show");
  }

  hide() {
    (window as any).Intercom("hide");
  }

  shutdown() {
    this.isBooted && (window as any).Intercom("shutdown");
  }

  prepareUserData(user) {
    const params = {
      app_id: appId,
      email: user.email,
      name: user.name || user.displayName,
      user_id: user.id || user.uid,
      created_at: Math.floor(Number(user.metadata?.createdAt) / 1000),
      number_of_workspaces: user.numberOfWorkspaces,
      number_of_recordings: user.numberOfRecordings,
      calendar_connected: user.calendarConnected,
      number_of_recordings_last_week: user.numberOfRecordingsLastWeek,
      user_hash: user.hash,
    };

    return params;
  }

  // Todo - updateAccount based on route changes
  //
  // updateAccount(account: any) {
  //   this.isBooted && this.intercom("update", {
  //
  //   })
  // };
}

export default {
  install(app, options) {
    // let intercom = new Intercom({ appId });
    intercom = new IntercomWrapper();
    app.config.globalProperties.$intercom = intercom;

    // provide for usage inside setup
    app.provide("intercom", intercom);
  },

  boot(user: User) {
    intercom.boot(user);
  },

  shutdown() {
    intercom.shutdown();
  },

  update(user: User) {
    intercom.update(user);
  },

  // startIntercomMessenger(user) {
  //   if (intercom.ready) {
  //     this.boot(user);
  //   } else {
  //     intercom.once("ready", () => this.boot(user));
  //   }
  // }
};
