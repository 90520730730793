<template>
  <RouterView />
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  watch,
  inject,
} from "vue";
import { RouterView, useRouter } from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useThemeStore } from "@/stores/theme";
import { useBodyStore } from "@/stores/body";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useAuthUserStore } from "@/stores/auth";
import { useQuery } from "@vue/apollo-composable";
import { provide, ref } from "vue";
import { GET_USER_AND_ACCOUNTS } from "@/core/gql/queries";
import { PostHog } from "posthog-js";
import { useLogger } from "vue-logger-plugin";
import { IntercomWrapper } from "@/core/plugins/intercom";

export default defineComponent({
  name: "app",
  components: {
    RouterView,
  },
  setup() {
    const themeStore = useThemeStore();
    const router = useRouter();
    const posthog = inject("posthog") as PostHog;
    const logger = useLogger();
    const intercom = inject("intercom") as IntercomWrapper;

    router.afterEach((to) => {
      // logger.log("captured event", to.fullPath);
      nextTick(() => {
        posthog.capture("$pageview", {
          $current_url: to.fullPath,
        });
      });
    });

    /**
     * @deprecated - we shouldnt be calling GET_USER_AND_ACCOUNTS on APP level
     * Get user and their accounts - set the data into
     */
    // const { result, loading, error, refetch } = useQuery(GET_USER_AND_ACCOUNTS);
    // watch(result, (changes) => {
    //   if (changes.whoami) {
    //     //Here we need to set the loading state to loaded if user is already resolved
    //     bodyStore.removeBodyClassName("page-loading");

    //     // TODO @navicstein still a dummy need to map to actual results
    //     // TODO if the route.params.channelId is not matching, redirect the user to /channels
    //     provide("currentAccount", {
    //       currentUser: changes.whoami,
    //       accounts: changes.accounts,
    //       currentAccount: changes.currentAccount,
    //     });
    //   }
    // });

    // /**
    //  * However if there's an error should send the user to login
    //  */
    // watch(error, (newError) => {
    //   // console.log(" New Error ", error);
    // });

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // configStore.overrideLayoutConfig();

      /**
       *  Sets a mode from configuration
       */
      themeStore.setThemeMode(themeMode.value);
    });

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/fonticon/fonticon.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";
@import "floating-vue/dist/style.css";

.swal2-popup .btn {
  margin: 0 0.5rem;
}

#app {
  display: contents;
}
</style>
