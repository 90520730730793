// Spanish

export default {
  dashboard: "ダッシュボード",
  layoutBuilder: "レイアウトビルダー",
  craft: "作成された",
  pages: "ページ",
  profile: "プロフィール",
  profileOverview: "概要",
  projects: "プロジェクト",
  campaigns: "キャンペーン",
  documents: "書類",
  connections: "接続",
  wizards: "ウィザード",
  horizontal: "横",
  vertical: "垂直",
  account: "アカウント",
  accountOverview: "概要",
  settings: "設定",
  authentication: "認証",
  basicFlow: "基本的な流れ",
  signIn: "サインイン",
  signUp: "サインアップ",
  passwordReset: "パスワードのリセット",
  multiStepSignUp: "マルチステップサインアップ",
  error404: "エラー404",
  error500: "エラー 500",
  apps: "アプリ",
  chat: "チャット",
  privateChat: "プライベートチャット",
  groupChat: "グループチャット",
  drawerChat: "ドロワーチャット",
  widgets: "ウィジェット",
  widgetsLists: "リスト",
  widgetsStatistics: "統計",
  widgetsCharts: "チャート",
  widgetsMixed: "混合",
  widgetsTables: "テーブル",
  widgetsFeeds: "フィード",
  changelog: "変更ログ",
  docsAndComponents: "ドキュメントとコンポーネント",
  megaMenu: "メガメニュー",
  exampleLink: "リンク例",
  modals: "モーダルズ",
  general: "一般",
  inviteFriends: "友達を招待",
  viewUsers: "ユーザーを表示します",
  upgradePlan: "アップグレードプラン",
  shareAndEarn: "シェア＆稼働",
  forms: "フォーム",
  newTarget: "新しいターゲット",
  newCard: "新しいカード",
  newAddress: "新しいアドレス",
  createAPIKey: "Apiキーを作成します",
  twoFactorAuth: "2つの要因Auth",
  createApp: "アプリを作成します",
  createAccount: "アカウントを作成する",
  activity: "アクティビティ",
  documentation: "ドキュメンテーション",
  components: "コンポーネント",
  resources: "資力",
  customers: "お客様のお客様",
  gettingStarted: "入門",
  customersListing: "顧客のリスト",
  customerDetails: "お客様の詳細",
  calendarApp: "カレンダー",
  subscriptions: "購読",
  getStarted: "入門",
  subscriptionList: "サブスクリプションリスト",
  addSubscription: "サブスクリプションを追加します",
  viewSubscription: "購読を見る",
  terms: "条項",
  plans: "プラン",
  contactUs: "お問い合わせ",
  or: "または",
  newHere: "ここに新しい？",
  haveAccount: "すでにアカウントを持っていますか?",
  iAgree: "同意します",
  termsAndConditions: "規約と条件",
  wait: "お待ちください...",
  submit: "送信",
  continue: "続く",
  continueWithGoogle: "を続行 Google",
  continueWithFacebook: "を続行 Facebook",
  continueWithApple: "を続行 Apple",
  form: {
    fnameLabel: "ファーストネーム",
    lnameLabel: "ラストネーム",
    emailLabel: "メール",
    passGuide:
      "文字、数字、記号を組み合わせた 8 文字以上の文字を使用してください。",
    passLabel: "パスワード",
    passConfirmationLabel: "パスワードを認証する",
    forgotPassword: "パスワードを忘れましたか?",
  },
  auth: {
    title: "ミーティングを意味のあるものにする",
    bio: "会議を記録、転写、要約します。 チームの全員が同じページにいるようにします。",
  },
};
