import { createApp, h, markRaw, provide } from "vue";
import { createPinia } from "pinia";
import { Tooltip } from "bootstrap";
import VuePlyr from "vue-plyr";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

// @ts-ignore
import { Calendar, DatePicker, setupCalendar } from "v-calendar";

import "vue-plyr/dist/vue-plyr.css";
import "v-calendar/dist/style.css";

import { EventBus, plugin as EventBusPlugin } from "@/assets/ts/EventBus";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/routes";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import posthogPlugin from "@/core/plugins/posthog";
import intercom from "@/core/plugins/intercom";
import googleAnalytics from "@/core/plugins/google-analytics";
import { Analytics } from "firebase/analytics";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import { createLogger } from "vue-logger-plugin";
import { DefaultApolloClient } from "@vue/apollo-composable";
import VueApolloComponents from "@vue/apollo-components";
import graphqlManager from "./graphql";

import type { Utils } from "@/core/helpers/utils";
import utils from "@/core/helpers/utils";

//Sequence might be important here - load app at the end of all libs
// @ts-ignore
import App from "./App.vue";
import { useAuthUserStore } from "@/stores/auth";
import { User, getAuth } from "firebase/auth";
import jwtService, { destroyToken } from "@/core/services/JwtService";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { LocationQueryRaw, Router } from "vue-router";
import { CookieComponent } from "./assets/ts/components";
import JwtService from "@/core/services/JwtService";
import { registerSW } from "virtual:pwa-register";
import { PostHog } from "posthog-js";

const registerPWA = (router: Router) => {
  router.isReady().then(async () => {
    const { registerSW } = await import("virtual:pwa-register");
    registerSW({ immediate: true });
  });
};

registerPWA(router);

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $utils: Utils;
    $bus: typeof EventBus;
    $posthog: PostHog;
    $analytics: Analytics;
    $intercom: typeof intercom;
  }
}

const isProd =
  import.meta.env.PROD === true || import.meta.env.VITE_APP_PROD === "true";

const app = createApp({
  setup() {
    provide(DefaultApolloClient, graphqlManager.apolloClient);

    // EventBus can be used for sending and receiving events
    // provide<IEventEmitter>("$bus", EventBus);
  },
  render: () => h(App),
});

app.use(Toast, <PluginOptions>{});

const logger = createLogger({
  enabled: true,
  level: isProd ? "error" : "debug",
});

if (isProd) {
  Sentry.init({
    app,
    environment: import.meta.env.PROD === true ? "production" : "development",
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost",
          import.meta.env.VITE_APP_BASE_URL,
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: isProd ? 0.05 : 1.0,
  });
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

function clearUserData() {
  jwtService.destroyToken();
  graphqlManager.purgeCacheIfUserChanged({});
  graphqlManager.apolloClient.cache.reset();
  // We may have some pages like invites outside the auth that needs to be left alone
  // instead of redirecting
  // setTimeout(() => {
  //   if (!router.currentRoute.value?.name?.toString().includes("shared"))
  //     router.replace({ name: "sign-in" });
  // }, 100);
}

// GraphqlManager on error handler
graphqlManager.onAuthError = (error) => {
  clearUserData();
};

// We can use this.$logger in pinia with this
declare module "pinia" {
  export interface PiniaCustomProperties {
    $log: typeof logger;
    $logger: typeof logger;
    logger: typeof logger;
    $bus: typeof EventBus;
  }
}
pinia.use(() => ({
  $log: logger,
  $logger: logger,
  logger: logger,
  $bus: EventBus,
}));

app.use(VuePlyr, {
  plyr: {},
});
// Setup plugin for defaults or `$screens` (optional)
app.use(setupCalendar, {});
// Use the components
app.component("CalendarControl", Calendar);
app.component("DatePicker", DatePicker);

// app.component()
app.use(logger);
app.use(utils);
app.use(EventBusPlugin);
app.use(pinia);
app.use(router);
app.use(ElementPlus);
app.use(graphqlManager.apolloProvider);
app.use(VueApolloComponents);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(posthogPlugin);
app.use(intercom);
app.use(googleAnalytics);
app.directive("tooltip", (el) => {
  new Tooltip(el);
});

app.mount("#app");

/**
 * React to user token changes from firebase
 * THIS SHOULD BE THE LAST THING IN MAIN.TS
 */
getAuth().onAuthStateChanged(async (user: User & { hash: string }) => {
  const authStore = useAuthUserStore();
  const inviteStr = CookieComponent.get("post-invite");

  // initiate user related activities
  if (user) {
    authStore.setAuth(user);

    const idToken = await user.getIdToken();
    await authStore.exchangeToken(idToken);

    user.hash = authStore.hash;

    logger.debug(
      "Main [onAuthStateChanged] firebase user is logged in as:",
      user.displayName
    );

    // dont show intercom window for everyone
    // intercom.boot(user);

    // we update to capture data for the new users that were not captured
    intercom.update(user);
    posthogPlugin.setUser(user);
    if (JwtService.getToken()) {
      logger.debug("will complete redirection for authenticated user");
      authStore.completeRedirection();
    }
  }

  if (!user) {
    clearUserData();
    // Fri Jun 16, 22 - removed the redirection + invites logic from here because
    // vue-router can't give us a `redirectedFrom` path that always works first time a user opens a page without authenticating first
    // this logic is moved to the routers' `beforeEach` middleware

    // query.redirect = router.currentRoute.value.redirectedFrom?.fullPath;
    // router.replace({ name: "sign-in", query: query });
  }
});
