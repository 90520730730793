// @ts-ignore
import { EventBus, EventTypes, ModalIds } from "@/assets/ts/EventBus";
// @ts-ignore
// import moment from "moment/min/moment-with-locales";
import { AccountUser, AuthUser } from "@/assets/ts/types";
import moment from "moment";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
// import i18n from "@/core/plugins/i18n";

// const { locale } = i18n.global;
try {
  moment.locale(localStorage.getItem("lang") || "en");
} catch (e) {
  console.error("Moment local error", e);
}

export interface ValidateImageOptions {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  width?: number;
  height?: number;
}

export enum ShareEntities {
  UNKNOWN,
  HUBSPOT,
  EMAIL,
  ZAPIER,
  SLACK,
}

class Utils {
  public userRoles;
  public mediaAccessLevels;
  /**
   * Helper to get modalIds in templates
   */
  public modals = ModalIds;
  private logger;

  constructor(logger) {
    this.logger = logger;

    // Users Resource Shared permission types
    this.userRoles = [
      {
        name: "collaborator",
        description:
          "Can edit transcript, create highlights, comment, and assign highlight tasks",
      },
      {
        name: "viewer",
        description: "Can view, comment and download transcripts only",
      },
    ];

    // Shared resource access levels
    this.mediaAccessLevels = [
      {
        name: "public",
        description: "Anyone with the media link has access",
      },
      {
        name: "restricted",
        description: "Only your shared with contacts have access to the media.",
      },
    ];
  }

  /**
   * Get initial out of a user or account object
   * @param {*} m
   * @returns
   */
  public initials(m: AccountUser | AuthUser) {
    const name = m.displayName || m.email;
    if (m && name) {
      const names = name.split(" ");
      return (
        names[0]?.charAt(0).toUpperCase() +
        (names.length > 1 ? names[1]?.charAt(0).toUpperCase() : "")
      );
    } else {
      return "A";
    }
  }

  /**
   * Shows a modal dialog
   * @param modalName
   * @param params
   */
  showModal<T = Record<string, string>>(modalName: string, params?: T) {
    this.logger.debug("Modal name: " + modalName, params);
    EventBus.$emit(EventTypes.showModal, { name: modalName, ...params });
  }

  /**
   * Hide modal
   * @param modalName
   */
  hideModal(modalName: string) {
    console.log("Hid Modal :" + modalName);
    EventBus.$emit(EventTypes.hideModal, { name: modalName });
  }

  dateTime(value, format) {
    return moment(value).format(format || "YYYY-MM-DD");
  }

  timeFromNow(date: string | moment.MomentInput) {
    return moment(date).fromNow();
  }

  /**
   *  The function uses the characters in the string to calculate a hash value, which is then used to generate a colour code
   * @param str
   * @returns
   */
  stringToColour(str: string): string {
    let i: number;
    let hash = 0;
    for (i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  /**
   * Convert alphabet to number for consistency in background colors
   * @param text
   */
  alphabetPosition(text) {
    let result = "";
    for (let i = 0; i < text.length; i++) {
      const code = text.toUpperCase().charCodeAt(i);
      if (code > 64 && code < 91) {
        result += code - 64 + " ";
      }
    }

    return result.slice(0, 1);
  }

  /**
   * Creates a randomly picked label class
   * @param index
   * @param dark
   * @returns {string}
   */
  randomBgClass(index: Number | String, dark = false) {
    if (typeof index === "string") {
      index = this.alphabetPosition(index);
    }
    let numbered = Number(index);
    const contextColors = ["warning", "success", "info", "primary", "danger"];
    // Restart iterating from beginning once looping ends;
    // this.$log.warn("indexToLabel", index)
    if (isNaN(numbered)) {
      numbered = Math.ceil(Math.random() * 1000000);
    }

    const light = dark ? "" : "light-";
    const inverse = dark ? "inverse-" : "";
    const color = contextColors[numbered % contextColors.length];
    return `bg-${light}${color} primary text-${inverse}${color}`;
  }

  /**
   * highlight types
   * @returns
   */
  public highlightTypes() {
    return [
      { name: "question", color: "#CBCCFF" },
      { name: "action", color: "#F0E5EE" },
      { name: "follow up", color: "#D3FBD8" },
      { name: "key point", color: "#1DCDAD" },
    ];
  }

  /**
   * Gets highlight color
   * @param topic string
   */
  public getHighlightTypeColor(topic: string) {
    return this.highlightTypes().find((type) => type.name === topic)?.color;
  }

  /**
   * Capitalizes a string or phrase of strings
   * @param words string
   * @returns string
   */
  public capitalize(words: string) {
    const wordsArr = words
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return wordsArr.join(" ");
  }

  /**
   * Resolves asset url
   * @param originalUrl string
   * @returns string
   */
  public resolveAssetURL(originalUrl) {
    const staticSrc = "/media/illustrations/placeholder-image.jpg";

    if (!originalUrl) {
      return staticSrc;
    }

    let url = null;

    // resolve for s3
    const s3Prefix = import.meta.env.VITE_APP_RECORDINGS_BUCKET;
    const cdn = import.meta.env.VITE_APP_RECORDINGS_CDN;

    if (originalUrl.startsWith("s3://")) {
      url = originalUrl.replace(s3Prefix, cdn);
    } else {
      url = originalUrl;
    }

    // trim left & right slashes
    if (url && !url.startsWith("/media")) {
      url = url.replace(/^\/|\/$/g, "");
      url = url.replaceAll(s3Prefix, "");
    }
    return url;
  }

  /**
   * Converts numeric time to HH:MM:Sec format
   * @param time number
   * @returns string
   */
  public formatNumericTime(time: number) {
    // time = time in mills
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return `${hours >= 1 ? hours + ":" : ""}${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  /**
   * Removes html from a string if any
   * @param htmlString string
   * @returns string
   */
  public stripHtml(htmlString: string): string {
    let str = htmlString.replace(/<[^>]+>/g, "");
    str = str.replaceAll("&nbsp;", "");
    return str.replace(/\s\s+/g, " ").trim();
  }

  /**
   * Confirm dialogue for deleting a resource
   * @param resource string
   */
  public async confirmDeletion(resource: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-sm btn-danger fs-5",
        cancelButton: "btn btn-sm btn-primary mt-0 fs-5",
        icon: "fs-6",
        title: "fs-4",
        actions: "d-flex align-items-center justify-content-around",
        validationMessage: "fs-5 py-2",
      },
      buttonsStyling: false,
    });
    const res = await swalWithBootstrapButtons.fire({
      title: `Are you sure you want to delete ${resource.toLowerCase()}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    });
    console.log(res.isConfirmed);
    return res.isConfirmed;
  }

  /**
   * mixin for chanl swal
   */
  public ChanlSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-sm btn-primary fs-5",
      cancelButton: "btn btn-sm btn-secondary mt-0 fs-5",
      icon: "fs-6",
      title: "fs-4",
      actions: "d-flex align-items-center justify-content-around",
      validationMessage: "fs-5 py-2",
    },
    reverseButtons: true,
    showCancelButton: true,
    buttonsStyling: false,
  });

  public async confirmCreation(resource: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-sm btn-primary fs-5",
        cancelButton: "btn btn-sm btn-secondary mt-0 fs-5",
        icon: "fs-6",
        title: "fs-4",
        actions: "d-flex align-items-center justify-content-around",
        validationMessage: "fs-5 py-2",
      },
      buttonsStyling: false,
    });
    const res = await swalWithBootstrapButtons.fire({
      title: `Are you sure you want to create ${resource.toLowerCase()}?`,
      text: `A new ${resource} will be created.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, create it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    });
    return res;
  }

  /**
   * Popup alert 
   * @param text 
   * @param icon 
   * @param title 
   * @param timer 
   */
  public async fireAlert(text, icon, title = "", timer = 1500) {
    await Swal.fire({
      text,
      icon,
      title: title,
      buttonsStyling: false,
      showConfirmButton: false,
      timer: timer,
      heightAuto: false,
    });
  }

  public getCurrentAccountId(): string {
    return sessionStorage.getItem("currentAccountId");
  }

  validateImageSize(
    resource: string,
    file: Blob,
    options: ValidateImageOptions
  ): Promise<Boolean> {
    const { width, height, minWidth, maxWidth, minHeight, maxHeight } = options;
    const fr = new FileReader();
    const img = new Image();
    const toast = useToast();

    return new Promise((resolve, reject) => {
      // Limit file upload size to 3mb
      if (file.size > 3 * 1000 * 1000) {
        toast.error("File size too large. Max file size allowed is 3MB");
        reject(false);
      }

      fr.onload = () => {
        // TODO - fix this, this too hard to process
        // resolving this temporarily
        return resolve(true);

        // file is loaded
        img.onload = () => {
          if (width && height && width === 0 && height === 0) {
            resolve(true);
          } else if (
            width &&
            height &&
            (img.width !== width || img.height !== height)
          ) {
            toast.error(
              `Make sure your ${resource} dimensions are ${width} x ${height}`
            );
            reject(false);
          } else if (
            minWidth &&
            maxWidth &&
            (img.width < minWidth ||
              img.width > maxWidth ||
              img.height < minHeight ||
              img.height > maxHeight)
          ) {
            toast.error(
              `Make sure your ${resource} dimensions are between ${minWidth} x ${minHeight} and ${maxWidth} x ${maxHeight}`
            );
            reject(false);
          } else {
            resolve(true);
          }
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(file);
    });
  }

  public waitForEl(selector): Promise<HTMLElement> {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector) as HTMLElement);
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector) as HTMLElement);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  public getCookieValue(name) {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1];
  }
}

export type { Utils };

export default {
  install: (app) => {
    // inject a globally available $utils object that can be used for static methods
    // $utils.initials(user)
    const utils = new Utils(app.config.globalProperties.$logger);
    app.config.globalProperties.$utils = utils;

    // provide for usage inside setup
    app.provide("utils", utils);
  },
};
