import { defineStore } from "pinia";

export const useChannelStore = defineStore("channel", {
  state: () => ({ currentAccount: null, currentAccountId: null }),
  actions: {
    setCurrentAccount(account: any) {
      this.currentAccount = account;
      this.setCurrentAccountId(account?.id)
    },
    setCurrentAccountId(accountId: any) {
      this.currentAccountId = accountId; // to be used in  transcript page
    },
  },
});
