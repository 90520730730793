import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
};

// console.log("Firebase config in UI ", firebaseConfig);
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

//Setup emulators
if (process.env.NODE_ENV === "development") {
  if (import.meta.env.VITE_APP_USE_EMULATORS === "ALL") {
    connectAuthEmulator(auth, "http://localhost:9099/", {
      disableWarnings: true,
    });
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectFirestoreEmulator(firestore, "localhost", 8082);

    // eslint-disable-next-line no-console
    console.log("Using All Emulators");
  } else if (import.meta.env.VITE_APP_USE_EMULATORS === "FNS") {
    // connectAuthEmulator(auth, "http://localhost:9099/");
    // fns.useEmulator("localhost", 5003);
    // eslint-disable-next-line no-console
    console.log("Using Functions Emulator");
  }
}
