//
// ██████╗  ██████╗ ██╗   ██╗████████╗███████╗███████╗██╗
// ██╔══██╗██╔═══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝██║
// ██████╔╝██║   ██║██║   ██║   ██║   █████╗  ███████╗██║
// ██╔══██╗██║   ██║██║   ██║   ██║   ██╔══╝  ╚════██║╚═╝
// ██║  ██║╚██████╔╝╚██████╔╝   ██║   ███████╗███████║██╗
// ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝╚═╝

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthUserStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import i18n from "@/core/plugins/i18n";
import { CookieComponent } from "@/assets/ts/components";

const { t } = i18n.global;

/**
 * Routes required
 *
 * /profile - user profile settings
 * /channels - list of all channels for this user
 * /channels/create - create a new channel
 * /channels/:channel/(home|transcripts|collections|notes|highlights|settings)
 * /channels/:channel/settings - account settings, now channel settings
 *
 * /media/:accountId-transcriptId - a single media
 * /highlight/:accountId-highlightId - a single highlight
 *
 * /streams
 * /streams/:stream
 *
 */
const routes: Array<RouteRecordRaw> = [
  // ╔═╗┌┬┐┌┐ ┌─┐┌┬┐┌─┐┌─┐┌┬┐┬
  // ║╣ │││├┴┐├┤  ││├┤ ├┤  │││
  // ╚═╝┴ ┴└─┘└─┘─┴┘└─┘└─┘─┴┘o
  // This is the entry point for the Chrome extension embedded pages for popup, options, and tabs pages
  // its only added here because it almost shares the same components with the main app
  {
    path: "/embedded/:type?",
    component: () => import("@/embedded/layouts/DefaultLayout.vue"),
    meta: {
      middleware: "auth",
      displaySidebar: false,
    },
    children: [
      {
        path: "popup",
        name: "embedded-popup",
        component: () => import("@/embedded/layouts/SharedEmbeddedLayout.vue"),
        children: [
          {
            path: "", // <- leave empty
            name: "embedded-popup-layout",
            redirect: {
              name: "embedded-recordings",
            },
            component: () => import("@/embedded/layouts/PopupLayout.vue"),
            children: [
              {
                path: "recordings",
                name: "embedded-recordings",
                component: () =>
                  import("@/embedded/components/popup/Recordings.vue"),
              },
              {
                path: "calendar",
                name: "embedded-calendar",
                component: () =>
                  import("@/embedded/components/popup/Calendar.vue"),
              },
            ],
          },
          {
            path: "settings",
            meta: {
              displayName: "Settings",
            },
            name: "embedded-setting",
            component: () => import("@/embedded/components/popup/Settings.vue"),
          },
        ],
      },
      {
        path: "content",
        name: "embedded-content",
        redirect: {
          name: "embedded-moments",
        },
        component: () => import("@/embedded/layouts/SharedEmbeddedLayout.vue"),
        children: [
          {
            path: "moments",
            name: "embedded-moments",
            component: () =>
              import("@/embedded/components/content/Moments.vue"),
          },
          {
            path: "settings",
            name: "embedded-moments-settings",
            component: () => import("@/embedded/components/Echo.vue"),
          },
        ],
      },
      // {
      //   path: "notes",
      //   name: "embedded-notes",
      //   component: () => import("@/embedded/components/EmbedNotePage.vue"),
      // },
      // {
      //   path: "moments",
      //   name: "embedded-moments",
      //   component: () => import("@/embedded/components/EmbedMomentsPage.vue"),
      // },
    ],
  },

  // ╔═╗┬ ┬┌─┐┌┐┌┌┐┌┌─┐┬  ┬
  // ║  ├─┤├─┤││││││├┤ │  │
  // ╚═╝┴ ┴┴ ┴┘└┘┘└┘└─┘┴─┘o
  {
    path: "/channels/:channelId/media/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    name: "media.details",
    redirect: "media.view",
    meta: {
      middleware: "auth",
      // headerDisplay: false,
    },
    children: [
      {
        path: ":mediaId",
        component: () => import("@/views/MediaDetail.vue"),
        name: "media.view",
        meta: {
          pageTitle: t("media"),
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/channels",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      pageTitle: t("welcome"),
      displaySidebar: true,
      // headerDisplay: true,
    },
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/layouts/AccountSettings.vue"),
        meta: {
          middleware: "auth",
          pageTitle: t("profile"),
          breadcrumbs: [{ name: t("profile"), to: "profile" }],
        },
        redirect: {
          name: "profile.overview",
        },
        children: [
          {
            path: "overview",
            name: "profile.overview",
            component: () => import("@/views/profile/Index.vue"),
            meta: {
              pageTitle: t("profileOverview"),
              breadcrumbs: [
                {
                  name: t("profile"),
                  to: "profile",
                },
                {
                  name: t("overview"),
                  to: "profile.overview",
                },
              ],
            },
          },
          {
            path: "privacy",
            name: "profile.privacySettings",
            component: () => import("@/views/profile/PrivacySettings.vue"),
            meta: {
              pageTitle: t("userPrivacySettings.privacySettings"),
              breadcrumbs: [
                { name: t("profile"), to: "profile" },
                {
                  name: t("userPrivacySettings.privacySettings"),
                  to: "profile.privacySettings",
                },
              ],
            },
          },
          {
            path: "extension",
            name: "profile.extensionSettings",
            component: () => import("@/views/profile/ExtensionSettings.vue"),
            meta: {
              pageTitle: t("userExtensionSettings.title"),
              breadcrumbs: [
                { name: t("profile"), to: "profile" },
                {
                  name: t("userExtensionSettings.title"),
                  to: "profile.extensionSettings",
                },
              ],
            },
          },
        ],
      },
      {
        path: "/channels",
        name: "channels",
        component: () => import("@/views/Channels.vue"),
        meta: {
          pageTitle: t("channels"),
          breadcrumbs: [
            { name: t("home"), to: "channels" },
            {
              name: t("channels"),
              to: "channels",
            },
          ],
        },
      },
      {
        path: "/channels/create",
        name: "channel.create",
        component: () => import("@/views/channel/ChannelCreate.vue"),
        meta: {
          middleware: "auth",
          // displaySidebar: false,
          breadcrumbs: [
            { name: t("home"), to: "channels" },
            { name: t("channel.name"), to: "channel.create" },
            { name: t("createNew"), to: "channel.create" },
          ],
        },
      },
      {
        path: "/channels/:channelId",
        redirect: { name: "channel.overview" },
        component: () => import("@/views/channel/Channel.vue"),
        meta: {
          middleware: "auth",
          breadcrumbs: [
            { name: t("home"), to: "channels" },
            { name: t("channel.name"), to: "channel.overview" },
          ],
        },
        children: [
          {
            path: "overview",
            name: "channel.overview",
            component: () => import("@/views/channel/Home.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("overview"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("channel.name"), to: "channel.overview" },
              ],
            },
          },
          {
            path: "streams",
            name: "channel.streams",
            component: () => import("@/views/channel/Streams.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("streams"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("streams"), to: "channel.streams" },
              ],
            },
          },
          {
            path: "highlights",
            name: "channel.highlights",
            component: () => import("@/views/channel/Highlights.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("highlights"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("highlights"), to: "channel.highlights" },
              ],
            },
          },
          {
            path: "notes",
            name: "channel.notes",
            component: () => import("@/views/channel/Notes.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("notes"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("notes"), to: "channel.notes" },
              ],
            },
            children: [
              {
                path: ":noteId",
                name: "single-note",
                component: () =>
                  import("@/views/channel/components/notes/SingleNote.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("notes"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("notes"), to: "channel.notes" },
                  ],
                },
              },
            ],
          },
          {
            path: "collections",
            name: "channel.collections",
            component: () => import("@/views/channel/Collections.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("collections"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("collections"), to: "channel.collections" },
              ],
            },
          },
          {
            path: "media",
            name: "channel.media",
            component: () => import("@/views/channel/Media.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("media"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("media"), to: "channel.media" },
              ],
            },
          },
          {
            path: "settings",
            name: "channel.settings",
            component: () => import("@/layouts/ChannelSettings.vue"),
            meta: {
              middleware: "auth",
              pageTitle: t("settings"),
              breadcrumbs: [
                { name: t("home"), to: "channels" },
                { name: t("settings"), to: "channel.settings" },
              ],
            },
            redirect: {
              name: "channel.settings.general",
            },
            children: [
              {
                path: "general",
                name: "channel.settings.general",
                component: () => import("@/views/channel/settings/Index.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("generalSettings"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    {
                      name: t("settings"),
                      to: "channel.settings",
                    },
                    {
                      name: t("general"),
                      to: "channel.settings.general",
                    },
                  ],
                },
              },
              {
                path: "team",
                name: "channel.settings.team",
                component: () => import("@/views/channel/settings/Team.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("teamSettings.title"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("settings"), to: "channel.settings" },
                    {
                      name: t("teamSettings.title"),
                      to: "channel.settings.team",
                    },
                  ],
                },
              },
              {
                path: "integrations",
                name: "channel.settings.integrations",
                component: () =>
                  import("@/views/channel/settings/Integrations.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("integrationsSettings.title"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("settings"), to: "channel.settings" },
                    {
                      name: t("integrationsSettings.title"),
                      to: "channel.settings.integrations",
                    },
                  ],
                },
              },
              {
                path: "vocabularies",
                name: "channel.settings.vocabularies",
                component: () =>
                  import("@/views/channel/settings/Vocabularies.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: "Team Vocabularies",
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("settings"), to: "channel.settings" },
                    {
                      name: t("vocabularySettings.title"),
                      to: "channel.settings.vocabularies",
                    },
                  ],
                },
              },
              {
                path: "billing",
                name: "account.billing",
                component: () => import("@/views/profile/Subscriptions.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("subscriptionSettings"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("channel.name"), to: "channel.overview" },
                    { name: t("settings"), to: "channel.settings" },
                    {
                      name: t("subscription"),
                      to: "account.billing",
                    },
                  ],
                },
              },
              {
                path: "usage",
                name: "account.usage",
                component: () => import("@/views/profile/Usage.vue"),
                meta: {
                  middleware: "auth",
                  pageTitle: t("subscriptionSettings"),
                  breadcrumbs: [
                    { name: t("home"), to: "channels" },
                    { name: t("channel.name"), to: "channel.overview" },
                    { name: t("settings"), to: "channel.settings" },
                    {
                      name: t("subscription"),
                      to: "account.usage",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "/channels/:channelId/plans",
        name: "plans",
        component: () => import("@/layouts/Plans.vue"),
        meta: {
          middleware: "auth",
          pageTitle: t("plansTitle"),
          breadcrumbs: [
            { name: t("channel.name"), to: "channel.overview" },
            {
              name: t("billing"),
              to: "account.billing",
            },
            { name: t("plans"), to: "plans" },
          ],
        },
      },
      {
        path: "/channels/:channelId/media/:mediaId",
        component: () => import("@/views/MediaDetail.vue"),
        name: "media.view",
        meta: {
          middleware: "auth",
          pageTitle: t("media"),
        },
      },
    ],
  },

  // This is temporary. TODO: Remove when shared pages are available
  {
    path: "/shared-items/:channelId",
    redirect: { name: "tempShared.items" },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "media",
        component: () => import("@/views/SharedItems.vue"),
        name: "tempShared.media",
        meta: {
          pageTitle: t("sharedMedia"),
          breadcrumbs: [
            { name: t("home"), to: "channels" },
            { name: t("media"), to: "shared.media" },
          ],
        },
      },
      {
        path: "highlights",
        component: () => import("@/views/SharedItems.vue"),
        name: "tempShared.highlights",
        meta: {
          middleware: "auth",
          pageTitle: t("sharedMedia"),
          breadcrumbs: [
            { name: t("home"), to: "channels" },
            { name: t("media"), to: "shared.media" },
          ],
        },
      },
    ],
  },

  // ╔═╗┬ ┬┌─┐┬─┐┌─┐┌┬┐┬
  // ╚═╗├─┤├─┤├┬┘├┤  │││
  // ╚═╝┴ ┴┴ ┴┴└─└─┘─┴┘o
  // Shared resources routes
  {
    path: "/shared/:channelId",
    redirect: { name: "shared.media" },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      pageTitle: "Welcome",
    },
    children: [
      {
        path: "",
        name: "shared.media",
        component: () =>
          import("@/views/channel/components/media/SharedMedia.vue"),
        meta: {
          middleware: "auth",
          pageTitle: t("sharedMedia"),
          breadcrumbs: [t("media")],
          displaySidebar: false,
        },
      },
      {
        path: ":shareId",
        name: "shared.highlight",
        component: () =>
          import("@/views/channel/components/media/SharedHighlight.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "Shared Highlight",
          breadcrumbs: ["Highlight"],
          displaySidebar: false,
        },
      },
    ],
  },

  // Media details page routes

  // ╔╦╗┌─┐┌┬┐┬┌─┐┬
  // ║║║├┤  │││├─┤│
  // ╩ ╩└─┘─┴┘┴┴ ┴o
  {
    path: "/media/:channelId",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    name: "media.shared",
    redirect: "shared-media",
    meta: {
      middleware: "auth",
      pageTitle: t("media"),
      displaySidebar: false,
      // headerDisplay: false,
    },
    children: [
      {
        path: "",
        component: () => import("@/views/MediaDetail.vue"),
        name: "shared-media",
        meta: {
          middleware: "auth",
        },
      },
    ],
  },

  // ╔═╗┬ ┬┌┬┐┬ ┬┬
  // ╠═╣│ │ │ ├─┤│
  // ╩ ╩└─┘ ┴ ┴ ┴o
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/invite/:invite",
        name: "show-invite",
        component: () => import("@/views/Invite.vue"),
        meta: {
          middleware: "auth",
          pageTitle: t("teamSettings.invite.name"),
          breadcrumbs: [t("teamSettings.invite.name")],
        },
      },
      {
        meta: {
          middleware: "auth",
        },
        path: "auth-success",
        name: "auth-success",
        component: () => import("@/views/AuthSuccess.vue"),
      },
      {
        path: "/integration/:providerName",
        name: "show-integration",
        component: () => import("@/views/Integration.vue"),
        meta: {
          middleware: "auth",
          pageTitle: t("teamSettings.invite.name"),
          breadcrumbs: [t("teamSettings.invite.name")],
        },
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: t("signIn"),
        },
      },
      {
        path: "/sign-up/:strategy?",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: t("signUp"),
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: t("passwordReset"),
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: t("errors.404"),
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: t("errors.500"),
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthUserStore();
  const configStore = useConfigStore();
  const inviteStr = to.params.invite as string;

  // current page view title
  document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;

  // reset config to initial state
  configStore.resetLayoutConfig();

  // set layout metadata
  if (to.meta) {
    useConfigStore().setLayout(to.meta);
  }

  // verify auth token before each page change
  authStore.verifyAuth();

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (authStore.isAuthenticated) {
      next();
    } else {
      let redirectTo = "";

      if (inviteStr) {
        console.debug("[inviteStr]: set invite for user", inviteStr);
        CookieComponent.set("post-invite", inviteStr, {});
        redirectTo = "post-invite";
      } else {
        redirectTo = to.fullPath;
      }

      next({
        name: "sign-in",
        query: {
          redirect: redirectTo,
        },
      });
    }
  } else {
    next();
  }

  // update the current url on intercom
  // @ts-ignore
  window.Intercom("update", {
    current_route: to.fullPath,
  });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
