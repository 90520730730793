// Spanish
export default {
  welcome: "Bienvenida",
  home: "Hogar",
  dashboard: "Panel",
  layoutBuilder: "Constructor de diseño",
  channels: "Canales",
  streams: "Corrientes",
  highlights: "Reflejos",
  notes: "Notas",
  collections: "Colecciones",
  comments: "Comentarios",
  transcripts: "Transcripciones",
  actionItems: "Elementos de acción",
  questions: "Preguntas",
  speakers: "Oradoras",
  summary: "resumen",
  keywords: "Palabras clave",
  owner: "Dueño",
  media: "Medios de comunicación",
  sharedMedia: "Medios compartidos",
  sharedHighlights: "Destacados compartidos",
  toolbars: "Barras de herramientas",
  recordings: "Grabaciones",
  craft: "Elaborado",
  pages: "Paginas",
  profile: "Perfil",
  plans: "Planes",
  plansTitle: "Cambio de plan",
  overview: "Descripción general",
  profileOverview: "Información general del perfil",
  accountOverview: "Descripción de cuenta",
  projects: "Proyectos",
  campaigns: "Campañas",
  documents: "Documentos",
  connections: "Conexiones",
  wizards: "Magos",
  horizontal: "Horizontal",
  vertical: "Vertical",
  account: "Cuenta",
  settings: "Ajustes",
  authentication: "Autenticación",
  basicFlow: "Flujo básico",
  signIn: "Registrarse",
  signUp: "Inscribirse",
  signOut: "Desconectar",
  language: "Idioma",
  passwordReset: "Restablecimiento de contraseña",
  multiStepSignUp: "Regístrese Multi-Pasos",
  apps: "Aplicaciones",
  chat: "Chat",
  privateChat: "Chat privado",
  groupChat: "Grupo de chat",
  drawerChat: "Chat del cajón",
  widgets: "Widgets",
  widgetsLists: "Liza",
  widgetsStatistics: "Estadísticas",
  widgetsCharts: "Gráficos",
  widgetsMixed: "Mezclada",
  widgetsTables: "Mesas",
  widgetsFeeds: "Alimenta",
  changelog: "Registro de cambios",
  docsAndComponents: "Documentos & Componentes",
  megaMenu: "Mega menú",
  exampleLink: "Enlace de ejemplo",
  modals: "Modales",
  general: "General",
  generalSettings:"Configuración general",
  inviteFriends: "Invitar A Amigos",
  viewUsers: "Ver Usuarios",
  upgradePlan: "Plan De Actualización",
  shareAndEarn: "Compartir Y Ganar",
  forms: "Formas",
  newTarget: "Nuevo Objetivo",
  newCard: "Nueva Tarjeta",
  newAddress: "Nueva Direccion",
  createAPIKey: "Crea Clave De Api",
  twoFactorAuth: "Dos Factores",
  createApp: "Crear Aplicacion",
  createAccount: "Crear Una Cuenta",
  activity: "Actividad",
  documentation: "Documentación",
  components: "Componentes",
  resources: "Recursos",
  customers: "Clientes",
  gettingStarted: "Empezando",
  customersListing: "Listado De Clientes",
  customerDetails: "Detalles De Los Clientes",
  calendarApp: "Calendario",
  billing: "Facturación",
  subscriptions: "Suscripciones",
  getStarted: "Empezando",
  usage: "El Uso",
  billingAndUsage: "Billing and Usage",
  subscriptionList: "Lista De Suscripción",
  subscriptionSettings: "Configuración de suscripción",
  addSubscription: "Añadir Suscripción",
  viewSubscription: "Suscripción",
  help: "Ayuda",
  tour: "Toma un tour",
  getStartedAction: "Empezar",
  rewards: "Recompensas",
  terms: "Términos",
  contactUs: "Contacta con nosotras",
  takeHome: "Llévame a casa",
  pro: "Pro",
  or: "o",
  ok: "Ok",
  beta: "beta",
  here: "aquí",
  day: "día",
  days: "días",
  okGotIt: "¡Ok, lo tengo!",
  newHere: "Nueva aqui?",
  haveAccount: "Ya tiene una cuenta?",
  iAgree: "estoy de acuerdo con",
  about: "Acerca de",
  tosText: "Al continuar acepto las",
  termsOfUse: "Condiciones de uso",
  privacyPolicy: "política de privacidad",
  termsAndConditions: "Términos y condiciones",
  wait: "Espere por favor...",
  loading: "Cargando...",
  progressing: "Progresando...",
  transcribing: "Transcribiendo...",
  waiting: "Espera...",
  createNew: "Crear nueva",
  viewDetails: "Ver detalles",
  clickToEdit: "Haz click para editar",
  upgrade: "Mejora",
  premium: "La Prima",
  readMore: "leer más",
  lastUpdatedAt: "Actualizado en",
  lastUpdatedBy: "Última actualización por",
  noMediaCreatedYet: "Todavía no ha creado ninguna nota de medios.",
  createNewNote: "Crear nueva nota",
  orStartWithATemplate: "o empieza con una plantilla",
  browseMoreTemplates: "Explorar más plantillas",
  subscription: "Suscripción",
  useSelectedTemplate: "Usar plantilla seleccionada",
  editMetadata: "Editar metadatos",
  createClip: "Crear vídeo",
  loadMore: "Carga más",
  loadPrevious: "Cargar anterior",
  revoke: "Revocar",
  creating: "Creando",
  auth: {
    title: "Da sentido a tus reuniones",
    bio: "Grabe, transcriba, resuma sus reuniones. Mantenga a todos en su equipo en la misma página.",
  },
  intro: {
    welcome: {
      title: "Bienvenido a Chanl Ai",
      body: "Bienvenido a Chanl, a continuación le mostraremos cómo comenzar en unos pocos pasos",
    },
    sidebar:
      "Accede fácilmente a tus diferentes canales, elementos compartidos y más.",
    uploadRecording:
      "¿Desearías haber tenido esto antes? Te tenemos; simplemente cargue sus grabaciones desde su computadora o Google Drive.",
    recordMeeting:
      "¡Únase al bot a su reunión y comience a grabar de inmediato!",
    viewRecordingDetails:
      "Haga clic aquí para ver los detalles de una grabación; transcripciones, destacados, comentarios y más.",
    connectCalendar:
      "Conecta tu calendario para ver todas tus reuniones en un solo lugar.",
    recordingCardFeed:
      "Obtenga un resumen de una grabación de una manera simple y elegante.",
    recordingDetailsCta:
      "Haga clic aquí para ver los detalles de una grabación.",
    restart:
     "Siempre puede realizar este recorrido nuevamente, haga clic aquí para comenzar.",
  },
  form: {
    labels: {
      avatar: "Avatar",
      fullName: "Nombre completo",
      fname: "Nombre de pila",
      lname: "Apellido",
      email: "Correo electrónico",
      message: "Mensaje",
      sortBy: "Ordenar por",
      password: "Contraseña",
      passwordConfirmation: "Confirmar Contraseña",
      newPassword: "Nueva contraseña",
    },
    validationMessages: {
      passwordMatch: "Las contraseñas deben coincidir",
      passwordConfirmation: "Confirmación de contraseña",
    },
    placeholders: {
      searchRecordings: "Buscar dentro de las grabaciones",
      newPassword: "Ingrese nueva clave",
      passwordConfirmation: "Confirmar la contraseña",
      searchTranscript: "Busque palabras clave, transcripciones y más...",
    },
    selectOptions: {
      latest: "El último",
      oldest: "Más antiguo",
    },
    guides: {
      password:
        "Use 8 o más caracteres con una combinación de letras, números y símbolos.",
      imageFiles: "Tipos de archivos permitidos: png, jpg, jpeg, webp.",
    },
    actions: {
      newHere: "Nueva aqui?",
      forgotPassword: "¿Has olvidado tu contraseña?",
      upload: "Subir",
      uploadVideo: "Subir un video",
      select: "Seleccionar",
      submit: "Entregar",
      discard: "Desechar",
      record: "Grabar Reunión",
      continue: "Continuar",
      retry: "Rever",
      delete: "Borrar",
      share:"Compartir",
      cancel: "Cancelar",
      ok: "Ok",
      join: "Unirse",
      add: "Agregar",
      save: "Guardar",
      send: "Enviar",
      prev: "Anterior",
      next: "Próxima",
      notify: "Ser notificada",
      continueWithGoogle: "Continua con Google",
      continueWithFacebook: "Continua con Facebook",
      continueWithApple: "Continua con Apple",
      connectCalendar1: "Conectar",
      connectCalendar2: "Google Calendar",
      update: "Actualizar",
      updating: "Actualizando...",
      edit: "Editar",
      processing: "Procesando...",
      waiting: "esperando para unirme",
      refresh: "Actualizar",
      privacy: "Cambiar privacidad",
      skipForNow: "Saltar por ahora",
    },
  },
  channel: {
    name: "Canal",
    create: {
      welcome: "Bienvenido a Chanl",
      description: "Comencemos configurando su espacio de trabajo",
      step1: {
        title: "Cree su cuenta",
        description: "Comencemos configurando su espacio de trabajo",
      },
      step2: {
        title: "Personaliza tu experiencia",
        description: "Cuéntanos más sobre tu caso de uso",
      },
      step3: {
        title: "Conecta tu calendario",
        description:
          "Para que Chanl pueda ayudarte a grabar las próximas reuniones",
      },
      step4: {
        title: "Trae a tus compañeros de equipo",
        description:
          "Invita hasta 10 compañeros de equipo a tu espacio de trabajo",
      },

      cardTitle: "Cree su cuenta",
      name: "Nombre del canal",
      domain: "Dominio personalizado",
      teamSize: "¿Cuál es el tamaño de su equipo?",
      role: "¿Cuál es su rol?",
      describeYourself: "¿Qué te describe mejor?",
      action: "Crear canal",
      calendarOpts: {
        title:
          "Chanl necesita acceso a los eventos de su calendario para ayudarlo a ver y\n" +
          "capturar próximas reuniones",
      },
      quiz: {
        whichPlatforms: "¿Qué plataforma de reuniones utiliza a menudo?",
        meetingTypes: "¿Qué tipo de reuniones tendrá más a menudo?",
        meetingTypeOptions: {
          customerMeetings: {
            title: "Reuniones con clientes",
            description: "Ej. Reuniones con clientes, prospectos, socios, etc.",
          },
          internalMeetings: {
            title: "Reuniones internas",
            description: "Ej. Reuniones con su equipo, colegas, gerentes, etc.",
          },
        },
        postCallActions: {
          summarize: "Resumen",
          shareSummary: "Compartir resúmenes de IA",
          translate: "Traducir",
          transcribe: "Transcribir",
          recording: "Solo grabar",
        },
        howMany: "¿Cuántas reuniones realiza en una semana?",
        afterMeeting: "¿Qué le gustaría hacer después de grabar una llamada?",
      },
    },
    headerCustomization: {
      addImage: "Añadir imagen",
      changeImage: "Cambiar imagen",
      useColor: "usar color",
      changeColor: "Cambiar el color",
      addLogo: "Agregar logotipo",
      changeLogo: "Cambiar logotipo",
      headerModal: {
        title: "Seleccione un fondo para el canal",
      },
      logoModal: {
        title: "Seleccione un logotipo personalizado",
      },
    },
    alerts: {
      problemWithAccount: {
        title: "Hay un problema con esta cuenta",
        body: "La cuenta a la que intenta acceder no está disponible, es posible que tenga problemas constantes o es posible que no tenga suficientes permisos para verla",
      },
    },
    calendarOpts: {
      defaultCalendar: "Calendario predeterminado",
      calendarOpts: "Opciones de calendario",
      meetingsRecorderCanJoin: "El Grabador de Reuniones se unirá",
      extendedDescriptions: {
        joinAll:
          "unirse automáticamente a todos los eventos de tu calendario con un enlace de reunión válido y compatible",
        joinIfHost:
          "unirse automáticamente a todos los eventos del calendario que sean creados o administrados por ti",
        joinIfRecorderInvited:
          "unirse solo a los eventos cuando invites manualmente al grabador como participante en cada uno de tus eventos",
      },
      calendarSelect: {
        joinAll:
          "Unirse a todos los eventos del calendario con enlace de conferencia",
        joinIfHost:
          "Unirse solo a los eventos del calendario que soy el propietario",
        joinIfRecorderInvited:
          "Unirse a los eventos del calendario solo cuando invite a",
      },
      alerts: {
        disconnectionError: {
          title: "Sincronización en progreso, ¿desconectar?",
          text: "¿Desconectar un calendario durante la sincronización puede causar problemas inconsistentes, desconectar de todas formas?",
        },
        confirmDisconnection: {
          title: "¿Seguro que desea desconectar el calendario?",
          text: "Tendrá que volver a conectar su calendario para tener acceso a sus eventos. ¿Continuar?",
        },
        settingsSaved: {
          title: "La configuración del calendario se ha guardado correctamente",
          text: "Configuración guardada exitosamente",
        },
        disconnectionSuccess: {
          text: "¡El calendario ha sido desconectado!",
        },
      },
    },
    calendarWidget: {
      title: "tu calendario",
      syncing: "Sincronizando...",
      calendarInUseTitle: "Calendario en uso en una cuenta diferente",
      calendarInUseText:
        "Este calendario ha sido conectado a una cuenta diferente, para evitar problemas de grabación y sincronización, hemos limitado la conexión de calendarios a una sola cuenta",
      syncingCompleted: "¡Sincronización completada!",
      refreshingEvents: "Eventos refrescantes...",
      alerts: {
        noMeetings: {
          title: "no tienes reuniones",
          body: "Parece que no tiene reuniones programadas para el mes, puede crear nuevos eventos en su aplicación de calendario y actualizar esta página",
        },
        notConnectedCalendar: {
          title: "No has conectado un calendario.",
          body: "Conecta tu calendario para ver y administrar tus eventos de muchas maneras",
        },
        calendarConnected: {
          body: "Ha conectado correctamente su calendario, espere uno o dos minutos mientras sincronizamos su calendario, ¡le informaremos cuando hayamos terminado!",
        },
        moveCalendar: {
          title: "¿Mover el calendario a esta cuenta?",
          text: "Este calendario ha sido conectado a una cuenta diferente, ¿desea desconectarlo de esa cuenta y transferir todos los eventos aquí?",
        },
        noMeeting: {
          title: "No hay reuniones por hoy",
          body: "Disfruta tu día sin reuniones",
        },
      },
    },
    highlightsWidget: {
      title: "Momentos clave",
      shortDescription: "de grabaciones",
    },
    feedWidget: {
      actionsMenu: {
        options: "Opciones",
        share: "Compartir",
        details: "Ver detalles",
        reEncode: "volver a codificar",
        transcribe: "Transcribir",
        downloadVideo: "Descargar video",
        downloadAudio: "Descargar Audio",
        viewDetails:"Ver detalles",
      },
      alerts: {
        consistencyViolation: {
          title: "¡Violación de coherencia!",
          body: "Algo salió mal con este medio, pero mientras tanto, puede volver a intentar procesar su video nuevamente y si falla, háganoslo saber.",
        },
        tooLong: {
          body: "Los medios tardaron demasiado en completarse, probablemente un error con los medios",
        },
        deleteMedia: {
          title: "¿Estás seguro de eliminar los medios?",
          body: "Sus medios están incompletos, puede eliminarlos o intentar solucionar el problema, ¿proceder a eliminarlos?",
        },
        noDescription: {
          body: "Sin descripción",
        },
        confirmDelete: {
          title: "¡Por favor confirmar!",
          body: "¿Estás segura de que quieres eliminar?",
        },
      },
    },
    query: {
      noResults: "Es solitario aquí, comencemos subiendo un video",
      noResultsForQuery:
        "No se encontraron resultados de búsqueda. Intente con otra consulta o cambie el filtro de privacidad a equipo.",
    },
    uploadMediaModal: {
      title: "Subir medios",
      form: {
        labels: {
          selectFiles: "Seleccionar archivos multimedia",
          title: "Título",
          selectLanguage: "Seleccione el idioma",
          advancedOptions: "Opciones avanzadas",
          audioSanitization: "Habilitar desinfección de audio",
          mediaSummarization: "Habilitar el resumen automático de medios",
          customVocabulary: "Habilitar vocabulario personalizado",
          mediaTitle: "Título del medio",
          transcriptionLanguage: "Lenguaje de transcripción",
        },
        placeholders: {
          select: "Seleccionar",
          mediaTitle: "Título de medios",
        },
        guides: {
          audioSanitization:
            "Podemos limpiar el audio antes de transcribirlo para que las transcripciones sean más claras.",
          mediaSummarization:
            "Utilice nuestra función de resumen automático impulsada por IA para generar automáticamente resúmenes, temas, preguntas, palabras clave y elementos de acción de sus medios",
          customVocabulary:
            "Use vocabularios personalizados en su cuenta para ayudarnos a comprender el contexto de la transcripción",
        },
      },
      alerts: {
        selectVideo:
          '"Seleccione archivos de video o audio para transcribir, máximo 100 MB"',
      },
    },
    botModal: {
      title: "Únase a una reunión",
      form: {
        labels: {
          linkInvalid:
            "El enlace de esta reunión no se ve bien, asegúrese de que sea un enlace de invitación",
          enterLink: "Ingrese el enlace de la reunión",
          selectLanguage: "Seleccionar idioma de transcripción",
          languageCode: "Código de lenguaje",
          meetingLink: "Enlace de reunión",
        },
        placeholders: {
          selectLanguage: "Seleccione un idioma de transcripción",
        },
        guides: {
          selectLanguage: "El bot transcribirá la reunión en este idioma.",
        },
        actions: {
          leaveMeeting: "Para de grabar",
        },
      },
      alerts: {
        joinMeeting: "Un bot se unirá a la reunión pronto",
        leaveMeeting: "dejará la reunión",
      },
    },
  },
  accountSettings: {
    title: "Cuenta",
    form: {
      labels: {
        accountName: "Configuraciones de la cuenta",
        name: "Nombre",
        domain: "Dominio",
        transcriptionLanguage: "Idioma predeterminado para la transcripción",
        country: "País",
        botDefaultMessageTip:
          "Los bots de mensajes automáticos envían cuando se unen a una llamada.",
        botDefaultMessage:
          "¡Saludos! Soy un grabador automático autorizado para grabar esta conversación. Estamos comprometidos a salvaguardar su privacidad y mantener la seguridad de los datos.",
          recorderPrivacy: "Privacidad de la grabadora",
          enabled:"Editar y Activar "
      },
      validationLabels: {
        headerImage: "Imagen de cabecera",
        accountLogo: "Logo",
        color: "Color",
        country: "País",
        min4Chars: "mínimo de 4 caracteres",
        max30Chars: "máximo de 30 caracteres",
        domainStructure: "Letras pequeñas con un número opcional o un guión",
        uniqueDomain: "Un dominio único entre 6 y 20 caracteres",
        invites: "invita",
        language: "Idioma",
        businessName: "Nombre del Negocio",
        preference: "Preferencia",
        role: "Role",
        tools: "herramientas",
        useCase: "caso de uso",
        vocabulary: "Vocabulario",
        botsJoinMessage: "Bots join message",
      },
      placeholders: {
        selectLanguage: "Seleccione el idioma",
        selectCountry: "Seleccionar país",
      },
      guides: {
        domain: "Para personalizar los enlaces para compartir,",
        domainWarning:
          "al cambiar esto, perderá el acceso a los enlaces compartidos anteriormente",
      },
    },
    alerts: {
      noPermission:
        "Es posible que no tenga permisos para actualizar esta cuenta",
      updatedAccount: "Cuenta actualizada correctamente.",
      deletedAccount: "Account marked for deletion successfully.",
    },
  },
  vocabularySettings: {
    title: "Vocabularios",
    description:
      "El vocabulario personalizado nos ayuda a comprender mejor el habla murmurada, distorsionada o difícil de descifrar. Puede especificar palabras clave a las que debemos prestar especial atención para ayudarnos a comprender el contexto de la transcripción. Las mejores prácticas para palabras clave de vocabulario incluyen:",
    bulletPoints: {
      "1": "Agregue palabras clave individuales en lugar de frases. Envíe solo las principales palabras poco comunes dentro de las frases de interés.",
      "2": "No utilice números y letras juntos en cadenas.",
      "3": 'Agregue palabras clave poco comunes. Evite agregar palabras comunes como "y" o "el", "pero", "nosotros".',
    },
    form: {
      labels: {
        language: "Idioma para este vocabulario",
      },
      placeholders: {
        selectLanguage: "Seleccione el idioma",
      },
    },
    alerts: {
      savedVocabulary: "Vocabulario guardado",
      reachedLimit: "Límite alcanzado de 200 palabras clave",
      phraseWarning: "La palabra clave no debe ser una frase",
      onlyStringsWarning: "La palabra clave debe contener solo cadenas",
      lengthWarning: "Se requieren al menos una o más palabras clave",
    },
  },
  integrationsSettings: {
    title: "Integraciones",
  },
  teamSettings: {
    title: "Equipo",
    invite: {
      name: "Invitar",
      title: "Invitar equipo",
      inviteLink: "Enlace de invitación",
      pendingInvites: "Invitaciones pendientes",
      Pending:"Pendiente"
    },
    form: {
      labels: {
        inviteLink: "Enlace de invitación",
        invitations: "Invitaciones",
        linkInviteExpiryPeriod:
          "Período de vencimiento del enlace de invitación",
        mailLinkExpiryPeriod:
          "Período de vencimiento de la invitación por correo",
        emails: {
          press: "Prensa",
          shortcut: "Ctrl + Enter",
          action: "para agregar correo electrónico",
        },
      },
      actions: {
        resendInvite: "Reenviar invitación",
        copyLink: "Copiar enlace de invitación",
        regenerate: "Regenerada",
        delete: "Borrar",
        share:"Compartir",
      },
      feedback: {
        copied: "¡Copiada!",
      },
    },
    tables: {
      headers: {
        name: "Nombre",
        joinedOn: "Unido el",
        role: "Role",
      },
    },
    alerts: {
      noInvites: "Sin invitaciones pendientes",
      emailInvalid:
        "¡Por favor introduzca una dirección de correo electrónico válida!",
      inviteExists: "El correo electrónico ya forma parte de las invitaciones.",
      memberExists: "El correo electrónico ya existe en el equipo.",
      cantInviteSelf: "no puedes invitarte a este espacio de trabajo",
      inviteSent: "Invitaciones enviadas con éxito.",
      inviteFailed: "Failed to send invite(s).",
    },
  },
  profileSettings: {
    title: "detalles del perfil",
    emailSection: "Dirección de correo electrónico",
    passwordSection: "Contraseña",
  },
  navbar: {
    userMenu: {
      accountSettings: "Configuraciones de la cuenta",
      profile: "Mi perfil",
      projects: "Mis proyectos",
      subscriptions: "mis Suscripciónes",
      referrals: "Referencias",
      billing: "Facturación",
      payments: "Pagos",
      statements: "Mis estados de cuenta",
      notifications: "Notificaciones",
    },
  },
  theme: {
    light: "Ligera",
    dark: "Oscura",
    system: "Sistema",
  },
  upload: {
    modal: {
      sanitize: {
        title: "Habilitar desinfección de audio",
        help: "Podemos limpiar el audio antes de transcribirlo para que las transcripciones sean más claras.",
      },
      customvocabulary: {
        title: "Habilitar vocabulario personalizado",
        help: "Use vocabularios personalizados en su cuenta para ayudarnos a comprender el contexto de la transcripción",
      },
    },
  },
  errors: {
    "404": "Error 404",
    "500": "Error 500",
    generic: "Ocurrió un error",
    noResults: "No hay resultados",
    transcriptNotFoundError: "La transcripción de este medio aún no está disponible",
    queryNotFound:
      "No se encontraron resultados de búsqueda. Intenta con otra consulta",
  },
  fakeMessages: {
    generatingAudio: "Generando audio y miniaturas...",
    generatingTranscript: "Generando transcripción...",
    anyMoment: "En cualquier momento ahora...",
  },
  comingSoon: {
    title: "Muy pronto",
    pages: {
      media: {
        title: "Organiza tus videos y audios",
        body: "Desde esta página, puede buscar todos sus videos y audios por palabras clave, transcripciones o personas. Organice videos por carpetas o automatice los flujos de trabajo de importación o exportación con otros sistemas.",
      },
      streams: {
        title: "Secuencias para reuniones recurrentes",
        body: "Convierta sus reuniones diarias o semanales o múltiples discusiones con un solo cliente en un solo enlace de información que se puede compartir con todas las notas, elementos de acción, grabaciones, transcripciones y archivos en un solo lugar.",
      },
      notes: {
        title: "Notas colaborativas",
        subTitle: "de grabaciones y reuniones",
        body: "Tome notas rápidas durante la llamada o de las grabaciones posteriores. Las notas se pueden adjuntar a sus grabaciones o secuencias, o se pueden compartir con otras personas individualmente. Agrega puntos destacados, videos y elementos de acción en las notas.",
      },
      subscriptions: {
        title: "Suscripciones",
        body: "¡Nuestra página de suscripciones ya casi está aquí! Manténgase atento a las actualizaciones sobre cuándo puede registrarse para obtener contenido exclusivo.",
      },
      shared: {
        title: "Elementos compartidos",
        body: "Podrá ver todos los elementos compartidos en una vista consolidada. Manténganse al tanto.",
      },
    },
  },
  recordingsPage: {
    title: "Todos los medios",
    subtitle: "Un lugar para todos los medios, tanto videos como audio.",
    activeText: "Colecciones",
    inactiveText: "Medios",
    addCollection: "Añadir colección",
    editCollection: "Editar colección",
    noCollectionsFound: "Es solitario aquí. Comience por crear una colección.",
    noSearchCollectionsFound:
      "No se encontraron colecciones para esta consulta. Por favor intente con otra consulta!",
    noMediaFound: "¡No se encontraron medios!",
    moveTo: "Mover a",
    moveToCollection: "Mover a una colección diferente",
    mediaFeed:"Fuente de medios",
    mediaCollections:"Colecciones de medios",
    Filter:"Filtrar",
    Hosts:"Hospedadores",
    Clear:"Clara",
    Type:"Tipo",
    Keywords:"Palabras clave",
    More:"Más",
    filterKeywords:"Filtrar palabras clave",
    all:"todos",
    clearAllFilters:"Borrar todos los filtros",
    results:"resultados",

  },
  mediaPage: {
    title: "Medios de comunicación",
    details: "Detalles de medios",
    transcripts:"Transcripciones",
  },
  createCollection: {
    collectionName: "Nombre de la colección",
    friendlyUrlTag: "Etiqueta de URL amigable",
    tagExample: "Ejemplo: https://chanl.ai/chanl/",
    description: "Descripción de la colección",
  },
  reEncodeMediaModal: {
    reTranscribeMedia: {
      title: "Vuelva a transcribir los medios",
      subtitle: "Solo vuelve a transcribir los medios al idioma seleccionado",
    },
    autoSummarize: {
      deleteAutoHighlights:
        "Elimine los aspectos destacados generados automáticamente disponibles para evitar la duplicación",
    },
    transcriptionLanguage: "Seleccionar idioma de transcripción",
  },
  userPrivacySettings: {
    privacySettings: "La configuración de privacidad",
    emailSettings: {
      heading: "Ajustes del correo electrónico",
      label: "Las actualizaciones de los medios y los resúmenes se envían a",
      subLabel:
        "Al completar el procesamiento de medios, el resumen de medios, las palabras clave y los capítulos se envían a",
      sendToOptions: {
        onlyMeAndTeamParticipants:
          "Enviar actualizaciones solo a mí y a los participantes de mi equipo",
        onlyMeAndNonTeamParticipants:
          "Enviar actualizaciones solo a mí y a los participantes fuera de mi equipo",
        everyone: "Enviar actualizaciones a todas las participantes",
        onlyMe: "Enviar actualizaciones solo a mí",
      },
    },
  },
  detailsPage: {
    header: {
      download: "Descargar",
      share: "Compartir",
      translate: "Traducir",
      back: "Atrás",
    },
  },
  editMetadataModal: {
    labels: {
      title: "título",
    },
  },
  plansPage: {
    header: "Precios",
    mainHeader: "Precios simples y asequibles",
    subHeader: "Obtenga Chanl y organice reuniones más productivas",
    currentPlan: "Usted está actualmente en",
    plan: "plan",
    yourSubscription: "Su suscripción a",
    is: "es",
    requiresAttention: "Por favor visita",
    customerPortal: "Portal del Cliente",
    yearly: "Anual",
    monthly: "Mensual",
  },
  proPlanOverlay: {
    title: " es una característica del plan Pro",
    learnMore: "Aprende más",
    contactSales: "Contactar con Ventas",
    upgradeToPro: "Actualízate a Pro",
    feature: "Historial de grabaciones",
    resource: "medios",
    description: `El acceso a los medios creados hace más de 15 días está limitado a un plan profesional. `,
    cta: "Actualice a un plan Pro para obtener acceso ilimitado a todo su historial de medios y más.",
    subject: "Necesito un plan profesional",
    text: "Hola estoy interesado en el plan Pro",
  },
  proPlanFeatures: {
    team: {
      label: "Agregar más miembros del equipo",
      description:
        "Puede hacer crecer su equipo invitando a más miembros, ya sea enviando un enlace a sus correos electrónicos o copiando el enlace de invitación y compartiéndolo con ellos.",
    },
  },
  billingPage: {
    accountStatus: "Estado de la cuenta",
    editPaymentDetails: "Editar detalles de pago",
    plan: "Plan",
    managePlan: "Administrar plan",
    billingEmail: "Correo Electrónico de Facturas",
    paymentMethod: "Método de pago",
    chargedAutomatically: "Cargado automáticamente",
    lastInvoice: "Última factura",
  },
  invoicePage: {
    invoice: "Factura",
    billedTo: "Facturado a",
    invoiceNumber: "Número de factura",
    invoiceDate: "Fecha de la factura",
    product: "Producto",
    amount: "Cantidad",
    amountDue: "Importe adeudado",
  },
  usagePage: {
    title: "Uso diario en minutos durante el último mes",
    yaxisLabel: "Minutos totales consumidos",
    date: "Fecha",
    minutes: "minutos",
  },
  noResourceAddedYet: {
    highlight: "Aún no se han añadido destacados",
  },
  integrationsPage: {
    connect: "Conectar",
    disconnect: "Desconectar",
    comingSoon: "Muy pronto",
    beta: "beta",
  },
  commentSuggestion: {
    summarizeToOneParagraph: "Chanl Resumir en un párrafo",
    conversationAbout: "Chanl ¿De qué fue la conversación?",
    keyPoints: "Chanl ¿Cuáles fueron los puntos clave de esta conversación?",
    actionItems: "Chanl extraer elementos de acción",
    improveConversation: "Chanl ¿Cómo se puede mejorar esta conversación?",
  },
  contactsMenu: {
    typeToSearch: "Escriba para buscar contacto",
    press: "Presiona",
    enter: "enter",
    toCreateContact: "para crear un nuevo contacto",
  },
  privacyOptions: {
    forMe: {
      text: "Para mí",
      description: "Solo accesible para usted como propietario",
    },
    team: {
      text: "Para el equipo",
      description: "Accesible para todos los miembros del equipo",
    },
    myRecordings: "Mi espacio",
    teamRecordings: "Espacio de equipo",
  },
  highlightsPage: {
    highlights: "Destacados",
    found: "encontró",
    searchHighlights: "Buscar reflejos",
    moreHighlights: "Más reflejos",
    createInitialHighlight:
      "Se siente solo aquí, comencemos creando un punto culminante de los medios",
    highlightNotFound:
      "Seleccione lo más destacado desde el lateral para ver los detalles de lo más destacado",
  },
  notesPage: {
    moreNotes: "Más notas",
    getStartedByCreatingNote: "comencemos creando una nota",
    browseTemplates: "Explorar plantillas",
    searchNotes: "Buscar notas",
    addNewNote: "Añadir nueva nota",
  },
  transcriptBodySegments: {
    noTranscriptFoundTitle: "Transcripción multimedia no encontrada!",
    noTranscriptFoundSubtitle:
      "Esto puede deberse a que los medios aún se están procesando, a los medios les falta audio o se produjo un error durante el procesamiento de los medios. Actualice para obtener la última transcripción para obtener el estado más reciente.",
    noTranscriptCTA: "Actualizar",
    loadPrevious: "Cargar anterior",
    loadMore: "Carga más",
  },
};
