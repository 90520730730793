import { createI18n } from "vue-i18n";
import en from "@/core/languages/en";
import es from "@/core/languages/es";
import de from "@/core/languages/de";
import ja from "@/core/languages/ja";
import fr from "@/core/languages/fr";

const messages = {
  en,
  es,
  de,
  ja,
  fr,
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
