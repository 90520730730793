// German

export default {
  dashboard: "Instrumententafel",
  layoutBuilder: "Layout-Builder",
  craft: "Hergestellt",
  pages: "Seiten",
  profile: "Profil",
  profileOverview: "Überblick",
  projects: "Projekte",
  campaigns: "Kampagnen",
  documents: "Unterlagen",
  connections: "Anschlüsse",
  wizards: "Zauberer",
  horizontal: "Horizontal",
  vertical: "Vertikal",
  account: "Konto",
  accountOverview: "Überblick",
  settings: "Die Einstellungen",
  authentication: "Authentifizierung",
  basicFlow: "Grundfluss",
  signIn: "Einloggen",
  signUp: "Anmelden",
  passwordReset: "Passwort zurücksetzen",
  multiStepSignUp: "Multi-Steps-Anmeldung",
  error404: "Fehler 404",
  error500: "Fehler 500",
  apps: "Apps",
  chat: "Plaudern",
  privateChat: "Private Chat",
  groupChat: "Privater Chat",
  drawerChat: "Gruppenchat Schubladen-Chat",
  widgets: "Widgets",
  widgetsLists: "Listen",
  widgetsStatistics: "Statistiken",
  widgetsCharts: "Diagramme",
  widgetsMixed: "Gemischt",
  widgetsTables: "Tabellen",
  widgetsFeeds: "Einspeisungen",
  changelog: "Änderungsprotokoll",
  docsAndComponents: "Dokumente & Komponenten",
  megaMenu: "Mega-Menü",
  exampleLink: "Beispiellink",
  modals: "Modale",
  general: "Allgemeines",
  inviteFriends: "Freunde Einladen",
  viewUsers: "Benutzer Anzeigen.",
  upgradePlan: "Upgrade-Plan",
  shareAndEarn: "Teilen & Verdienen",
  forms: "Formen",
  newTarget: "Neues Ziel",
  newCard: "Neue Karte",
  newAddress: "Neue Adresse",
  createAPIKey: "Api-Key Erstellen",
  twoFactorAuth: "Zwei Faktor Auth.",
  createApp: "App Erstellen",
  createAccount: "Benutzerkonto Erstellen",
  activity: "Aktivität",
  documentation: "Dokumentation",
  components: "Bauteile",
  resources: "Ressourcen",
  customers: "Kunden",
  gettingStarted: "Einstieg",
  customersListing: "Kundenauflistung",
  customerDetails: "Kundenangaben",
  calendarApp: "Kalender",
  subscriptions: "Abonnements",
  getStarted: "Einstieg",
  subscriptionList: "Abonnementliste",
  addSubscription: "Subskription Hinzufügen.",
  viewSubscription: "Abonnement Anzeigen.",
  terms: "Bedingungen",
  plans: "Pläne",
  contactUs: "Kontaktiere uns",
  or: "oder",
  newHere: "Neu hier?",
  haveAccount: "Sie haben bereits ein Konto?",
  iAgree: "Ich stimme zu",
  termsAndConditions: "Geschäftsbedingungen",
  wait: "Warten Sie mal...",
  submit: "Einreichen",
  continue: "Weitermachen",
  continueWithGoogle: "Weiter mit Google",
  continueWithFacebook: "Weiter mit Facebook",
  continueWithApple: "Weiter mit Apple",
  form: {
    fnameLabel: "Vorname",
    lnameLabel: "Nachname",
    emailLabel: "Email",
    passGuide:
      "Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
    passLabel: "Passwort",
    passConfirmationLabel: "Bestätige das Passwort",
    forgotPassword: "Passwort vergessen?",
  },
  auth: {
    title: "Machen Sie Sinn aus Ihren Meetings",
    bio: "Zeichnen Sie Ihre Meetings auf, transkribieren Sie sie und fassen Sie sie zusammen. Halten Sie alle in Ihrem Team auf dem gleichen Stand.",
  },
};
