import gql from "graphql-tag";

export const GET_PROFILE_AND_ACCOUNTS = gql`
  query {
    whoami {
      id
      email
      displayName
    }
    getProfile {
      id
      displayName
      email
      disabled
      extensionSettings {
        autoRecord
        selectedAccountId
        selectedLanguageCode
      }
    }
    accounts {
      id
      name
      language
      domain
      team {
        id
        role
        selectedCalendarId
        calendarConnected
        displayName
        photoURL
      }

    }
  }
`;

export const GET_USER_AND_ACCOUNTS = gql`
  query userAndAccounts($teamParams: GetTeamParams) {
    whoami {
      id
      displayName
      email
      disabled
    }
    accounts {
      id
      name
      logo
      account_header
      account_logo
      language
      domain
      team(teamParams: $teamParams) {
        id
        role
        selectedCalendarId
        displayName
        photoURL
      }
    }
  }
`;

export const GET_AUTH_USER = gql`
  query {
    whoami {
      id
      displayName
      email
      photoURL
      selectedCalendarId
      syncing
      disabled
    }
  }
`;

export const GET_CURRENT_ACCOUNT_TEAM = gql`
  query getAccount($accountId: String!, $teamParams: GetTeamParams) {
    account(id: $accountId) {
      id
      name
      team(teamParams: $teamParams) {
        id
        selectedCalendarId
        email
        inviteStatus
        created_at
        displayName
        photoURL
        role
        inviteStatus
      }
    }
  }
`;

export const LIST_CALENDAR_EVENTS = gql`
  query ListCalendarEvents(
    $eventParams: CalendarEventParams!
    $streamParams: ListStreamParams!
  ) {
    listStreams(streamParams: $streamParams) {
      id
      isLive
      name
      description
      startDate
      meetingLink
      endDate
      accountId
      currentBotId
    }
    listCalendarEvents(eventParams: $eventParams) {
      eventParams
      events {
        id
        meetingLink
        htmlLink
        conferenceType
        start
        end
        isScheduled
        summary
        creator
        isRecurring
        attendees
      }
    }
  }
`;

export const GET_CURRENT_ACCOUNT = gql`
  query ($accountId: String!) {
    account(id: $accountId) {
      id
      account_header
      account_logo
      color
      name
      country
      domain
      language
      city
      address
      logo
      organization
      plan
      preference
      role
      botsJoinMessage
      botsJoinMessageEnabled
      stripeCustomerId
      subscription {
        id
        status
      }
      userId
      invitation_token
      team {
        id
        email
        selectedCalendarId
        displayName
        photoURL
      }
    }
    # currentAccountUser {
    #   id
    #   displayName
    #   email
    #   role
    #   photoURL
    #   disabled
    #   connectedIntegrations
    #   syncing
    #   selectedCalendarId
    #   # Apr 23rd, 2023 -  fixed a weird bug in __typename being the same because on
    #   # injected "AccountUser" with different cached results from different queries
    #   calendarOptions {
    #     calendarId
    #     joinAll
    #     joinIfHost
    #     joinIfRecorderInvited
    #   }
    # }
  }
`;

export const LIST_CALENDARS = gql`
  query {
    listCalendars {
      id
      description
      summary
    }
  }
`;

export const GET_MEDIA_LIST_SHORT = gql`
  query MediaList($filters: VideoBrowseObjects!) {
    mediaList(filters: $filters) {
      id
      date {
        from
        to
      }
      pagination {
        currentPage
        itemsPerPage
        totalItems
        totalPages
      }
      videos {
        id
        title
        description
        image
        type
        transcriptId
        owner {
          id
          displayName
          email
          photoURL
        }
        created_at
      }
    }
  }
`;

export const GET_MEDIA_LIST = gql`
  query MediaList($filters: VideoBrowseObjects!) {
    mediaList(filters: $filters) {
      id
      date {
        from
        to
      }
      pagination {
        currentPage
        itemsPerPage
        totalItems
        totalPages
      }
      videos {
        id
        title
        description
        accountId
        src
        downloadableVideo
        audioFile
        created_at
        encodingStartedAt
        created_by
        keywords
        image
        type
        privacy
        botId
        botStatus {
          done
          status
          err
        }
        transcodingStatus {
          done
          err
          msg
          percent
          percentType
        }
        transcriptionStatus {
          done
          err
          msg
        }
        autoSummarizationStatus {
          done
          err
          msg
        }
        owner {
          id
          displayName
          email
          photoURL
        }
        # transcript {
        # id

        # 19th June, hotfix for ui perf problems - Dean
        # disabling per speaker fetch as this is delaying the whole UI
        # bySpeaker {
        #   speaker {
        #     displayName
        #     photoURL
        #     color
        #   }
        # }
        # }
        _snippetResult
        _highlightResult
      }
      facets
    }
  }
`;

export const GET_MEDIA_PROCESSING_STATUSES = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
    }
  }
`;

export const LIST_NOTES = gql`
  query ListNotes($listParams: ListNotesParams!) {
    listNotes(listParams: $listParams) {
      id
      userId
      accountId
      title
      description
      content
      templateId
      createdAt
      updatedAt
      updatedBy
      mediaId
      readOnly
      access
      lastUpdatedBy {
        id
        email
        photoURL
        displayName
      }
    }
  }
`;

export const FIND_NOTE = gql`
  query ($noteId: String!) {
    findNote(noteId: $noteId) {
      id
      userId
      accountId
      title
      description
      content
      templateId
      createdAt
      updatedAt
      updatedBy
      mediaId
      readOnly
      access
      lastUpdatedBy {
        id
        email
        photoURL
        displayName
      }
    }
  }
`;
export const FIND_NOTE_MINIMALISTIC = gql`
  query ($noteId: String!) {
    findNote(noteId: $noteId) {
      id
      userId
      accountId
      updatedAt
      lastUpdatedBy {
        id
        email
        photoURL
        displayName
      }
    }
  }
`;

export const LOOKUP_INVITE = gql`
  query lookupInvite($inviteStr: String!) {
    lookupInvite(inviteStr: $inviteStr)
  }
`;

export const GET_VOCABULARY = gql`
  query getVocabulary($languageCode: String) {
    vocabulary(languageCode: $languageCode) {
      accountId
      id
      keywords
      languageCode
    }
  }
`;

export const GET_NOTES_TEMPLATE = gql`
  query GetNoteTemplate {
    findNoteTemplates {
      id
      title
      description
      category
      json
      icon
    }
  }
`;

// TODO: merge with media query
export const GET_MEDIA_NOTES = gql`
  query MediaNotes($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      notes {
        id
        userId
        accountId
        title
        description
        content
        templateId
        createdAt
        updatedAt
        updatedBy
        mediaId
        readOnly
        access
      }
    }
  }
`;

export const GET_LATEST_ACCOUNT_HIGHLIGHTS = gql`
  query LatestAccountHighlights($howMany: Int!, $privacy: String!) {
    latestAccountHighlights(howMany: $howMany, privacy: $privacy) {
      id
      thumbnailSrc
      topics
      size
      endTime
      startTime
      transcript
      title
    }
  }
`;

export const SEARCH_HIGHLIGHTS = gql`
  query HighlightList($filters: HighlightBrowseObjects!) {
    highlightList(filters: $filters) {
      id
      highlights {
        id
        thumbnailSrc
        topics
        size
        endTime
        startTime
        transcript
        title
        mediaId
        src
        kind
        privacy
      }
      pagination {
        currentPage
        totalPages
        totalItems
      }
    }
  }
`;

export const GET_MEDIA_HIGHLIGHTS = gql`
  query MediaHighlights($mediaId: String!, $filters: QueryFilter!) {
    media(mediaId: $mediaId) {
      id
      highlights(filters: $filters) {
        id
        src
        thumbnailSrc
        startTime
        endTime
        topics
        kind
        transcript
        title
        size
      }
    }
  }
`;

export const GET_TRANSCRIPT_HIGHLIGHTED_SEGMENT = gql`
  query Media(
    $mediaId: String!
    $filters: QueryFilter!
    $browseParams: BrowseSegmentedTranscriptParams!
  ) {
    media(mediaId: $mediaId) {
      id
      transcript {
        id
        translations
        highlightedSegmentedTranscript(browseParams: $browseParams) {
          id
          start
          end
          text
          speaker
          resolvedSpeaker {
            id
            displayName
            photoURL
            color
          }
          words {
            start
            end
            text
          }
          index
        }
        pagination(browseParams: $browseParams) {
          currentPage
          itemsPerPage
          totalItems
          totalPages
        }
      }
      highlights(filters: $filters) {
        id
        transcript
        startTime
        endTime
        topics
      }
    }
  }
`;

export const BROWSE_SEGMENTED_TRANSCRIPT = gql`
  query ListHighlightedTranscriptSegments(
    $browseParams: BrowseSegmentedTranscriptParams!
  ) {
    listHighlightedTranscriptSegments(browseParams: $browseParams) {
      id
      segments {
        id
        start
        end
        text
        speaker
        resolvedSpeaker {
          id
          displayName
          photoURL
          color
        }
        words {
          start
          end
          text
        }
        index
        _highlightResult {
          text {
            value
          }
        }
      }
      pagination {
        currentPage
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_TRANSCRIPT_SPEAKERS = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      transcript {
        id
        bySpeaker {
          speaker(mediaId: $mediaId) {
            id
            displayName
            photoURL
            color
          }
        }
      }
    }
  }
`;

export const GET_COLLECTION_LIST = gql`
  query ListCollections($filters: QueryFilter!) {
    listCollections(filters: $filters) {
      id
      name
      description
      channel_logo
      channel_header
      created_at
      totalMedia
      slug
      created_by
    }
  }
`;

export const GET_SPEAKER_ANALYSIS = gql`
  query MediaSpeakerAnalysis($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      transcript {
        id
        consolidated {
          color
          end
          start
          type
          width
        }
        bySpeaker {
          percent
          segments {
            color
            width
            start
            type
          }
          speaker(mediaId: $mediaId) {
            id
            displayName
            photoURL
            color
          }
          totalSpokenTime
        }
      }
    }
  }
`;
export const GET_SPEAKER_ANALYSIS_AUDIO = gql`
  query MediaSpeakerAnalysis($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      transcript {
        id
        consolidated {
          color
          end
          start
          type
        }
        bySpeaker {
          speaker(mediaId: $mediaId) {
            id
            displayName
            photoURL
            color
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_CONTACTS = gql`
  query ListContacts($listContactsParams: ListContactsParams!) {
    listContacts(listContactsParams: $listContactsParams) {
      id
      contacts {
        id
        displayName
        photoURL
        email
      }
      pagination {
        currentPage
        totalItems
        totalPages
      }
    }
  }
`;

export const GET_MEDIA_TRANSCRIPT_HEADER = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      title
      created_at
      duration
      keywords
      description
      type
      src
      image
      owner {
        id
        displayName
      }
      transcript {
        id
        bySpeaker {
          speaker(mediaId: $mediaId) {
            id
            displayName
            photoURL
            color
          }
        }
      }
      defaultNote {
        id
      }
    }
  }
`;

export const FETCH_HUBSPOT_CONTACTS = gql`
  query {
    fetchHubspotContacts {
      displayName
      email
      id
    }
  }
`;
export const GET_ACCOUNT_LIMITS = gql`
  query Account($id: String!, $metrics: [String!]!) {
    account(id: $id) {
      id
      plan
      limitStatuses(metrics: $metrics) {
        metric
        limit
        status
      }
    }
  }
`;

export const GET_SHARED_MEDIA = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      title
      description
      src
      downloadableVideo
      audioFile
      created_at
      created_by
      keywords
      image
      type
      transcodingStatus {
        done
        err
        msg
        percent
        percentType
      }
      transcriptionStatus {
        done
        err
        msg
      }
      owner {
        id
        displayName
        photoURL
      }
      transcript {
        id
        bySpeaker {
          speaker(mediaId: $mediaId) {
            displayName
            photoURL
            color
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_SUBSCRIPTION_STATUS = gql`
  query AccountSubscriptionStatus($id: String!) {
    accountSubscriptionStatus(id: $id) {
      id
      plan
      subscriptionStatus
      owner {
        id
        email
        role
      }
    }
  }
`;

export const GET_MEDIA_SHARED_WITH_USERS = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      owner {
        id
        displayName
        email
        photoURL
        role
      }
      sharedWith {
        email
        role
      }
      sharedWithUsers {
        id
        photoURL
        displayName
        email
        sharedRole
      }
    }
  }
`;
export const GET_HIGHLIGHT_SHARED_WITH_USERS = gql`
  query Highlight($highlightId: String!) {
    highlight(highlightId: $highlightId) {
      id
      owner {
        id
        displayName
        email
        photoURL
        role
      }
      sharedWith {
        email
        role
      }
      sharedWithUsers {
        id
        photoURL
        displayName
        email
        sharedRole
      }
    }
  }
`;

export const GET_PLANS = gql`
  query GetPlans {
    getPlans {
      id
      interval
      name
      description
      price
      features
      unit
      stripe {
        coupon {
          percent
        }
        price
        interval
      }
    }
  }
`;

export const GET_CURRENT_BOT = gql`
  query ($botId: String!) {
    getSingleBot(id: $botId) {
      id
      accountId
      status
      meetingLink
      cmd
      createdAt
      languageCode
      mediaId
      meetingLink
      message
      name
    }
  }
`;

export const GET_TRANSCRIPT_HEADER = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      title
      # transcript {
      #   id
      #   bySpeaker {
      #     speaker(mediaId: $mediaId) {
      #       color
      #       displayName
      #       email
      #       id
      #       inviteStatus
      #       photoURL
      #       role
      #     }
      #   }
      # }
      owner {
        id
        displayName
        photoURL
      }
      created_at
      duration
      privacy
      created_by
      transcodingStatus {
        done
        err
        msg
        percent
        percentType
      }
      transcriptionStatus {
        done
        err
        msg
      }
      autoSummarizationStatus {
        done
        err
        msg
      }
    }
  }
`;
export const GET_MEDIA_DEFAULT_NOTE = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      defaultNote {
        id
      }
    }
  }
`;

export const GET_TRANSCRIPT_PLAYER = gql`
  query Media($mediaId: String!) {
    media(mediaId: $mediaId) {
      id
      type
      src
      image
      thumbnail
      downloadableVideo
    }
  }
`;

export const GET_RESOURCE_OWNER = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      id
      email
    }
  }
`;
export const GET_CURRENT_CALENDAR_USER = gql`
  query CurrentAccountUser {
    currentAccountUser {
      id
      displayName
      email
      role
      photoURL
      disabled
      connectedIntegrations
      syncing
      selectedCalendarId
      numberOfWorkspaces
      numberOfRecordings
      numberOfRecordingsLastWeek
      calendarConnected

      # Apr 23rd, 2023 -  fixed a weird bug in __typename being the same because on
      # injected "AccountUser" with different cached results from different queries
      calendarOptions {
        calendarId
        joinAll
        joinIfHost
        joinIfRecorderInvited
      }
    }
  }
`;

export const GET_MEDIA_COMMENTS = gql`
  query MediaComments($mediaId: String!, $filters: QueryFilter!) {
    media(mediaId: $mediaId) {
      id
      comments(filters: $filters) {
        id
        body
        mediaId
        created_at
        sender {
          id
          photoURL
          displayName
          email
        }
        replyTo
      }
    }
  }
`;

export const GET_HIGHLIGHT = gql`
  query Highlight($highlightId: String!) {
    highlight(highlightId: $highlightId) {
      id
      thumbnailSrc
      topics
      size
      endTime
      startTime
      transcript
      title
      mediaId
      src
      kind
      extension
      owner {
        id
        displayName
        photoURL
        email
      }
      created_at
      privacy
    }
  }
`;

export const BROWSE_NOTES = gql`
  query BrowseNotes($browseParams: NotesBrowseObjects!) {
    browseNotes(browseParams: $browseParams) {
      id
      notes {
        id
        title
        updatedAt
        createdByUser {
          id
          displayName
          photoURL
        }
      }
      pagination {
        currentPage
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
